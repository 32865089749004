import React from 'react';
import styled from 'styled-components';
import { ClaimProposalDTO, ClaimProposalDTOStatusEnum } from '@reposit/api-client';
import {
  isAwaitingTenantPayment,
  isTenantDraftProposal,
  isTenantAwaitingResponse,
  isSupplierDraftingProposal,
} from '../../utils/common.utils';
import { some } from 'lodash';

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 4px;
  text-align: center;
  width: 100%;
`;

const ResponseText = styled.span`
  color: ${(props) => props.theme.colors.primaryBrand};
  display: block;
  font-family: ${(props) => props.theme.typography.face.secondary};
  font-size: 0.95em;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 4px;
`;

const ExplanationText = styled.span`
  color: ${(props) => props.theme.colors.body2};
  display: block;
  font-family: ${(props) => props.theme.typography.face.secondary};
  font-size: 0.8em;
  line-height: 1.3;
`;

type TenantState = 'draft' | 'payment' | 'submitted' | 'awaitingSupplier' | 'unavailable' | 'inactive' | 'autoResponded';

type TenantActionState = {
  message: string;
  explanation: string;
};

const getTenantActionState = (proposals: ClaimProposalDTO[]): TenantActionState => {
  const tenantStartedDraftProposal = isTenantDraftProposal(proposals);
  const tenantStartedPayment = isAwaitingTenantPayment(proposals);
  const tenantAwaitingResponse = isTenantAwaitingResponse(proposals);
  const supplierDraftingProposal = isSupplierDraftingProposal(proposals);
  const tenantDisputed = some(proposals, { status: ClaimProposalDTOStatusEnum.DISPUTED });
  const claimSettled = some(proposals, { status: ClaimProposalDTOStatusEnum.SETTLED });
  const autoResponded = some(proposals, { autoResponded: true });

  const tenantActionStates: Record<TenantState, TenantActionState> = {
    draft: {
      message: 'Tenant has begun responding',
      explanation: "The tenant has started working on their response to a proposal but hasn't completed it yet.",
    },
    payment: {
      message: 'Tenant has progressed to payment stage',
      explanation: 'The tenant has moved forward to the payment phase of the proposal process.',
    },
    submitted: {
      message: 'Tenant has submitted a response',
      explanation: 'The tenant has completed and submitted their response to a proposal.',
    },
    awaitingSupplier: {
      message: 'Tenant is awaiting a new proposal',
      explanation: 'The supplier is currently working on creating a proposal, and the tenant is waiting for it.',
    },
    unavailable: {
      message: 'No tenant action available',
      explanation:
        'The claim has not been raised yet or it has been settled or disputed. Meaning no action is needed from the tenant.',
    },
    inactive: {
      message: 'Tenant has not yet begun responding',
      explanation: "A proposal has been sent to the tenant, but they haven't started working on their response yet.",
    },
    autoResponded: {
      message: 'Tenant has missed the deadline',
      explanation: 'This proposal has been auto-accepted.',
    },
  } as const;

  let stateKey: TenantState = 'inactive';
  if (tenantDisputed || claimSettled) {
    stateKey = 'unavailable';
    // Below unavailable so it only appears if the case isn't settled or disputed
  } else if (autoResponded) {
    stateKey = 'autoResponded';
  } else if (tenantStartedDraftProposal) {
    stateKey = 'draft';
  } else if (tenantStartedPayment) {
    stateKey = 'payment';
  } else if (tenantAwaitingResponse) {
    stateKey = 'submitted';
  } else if (supplierDraftingProposal) {
    stateKey = 'awaitingSupplier';
  }

  return tenantActionStates[stateKey];
};

interface TenantActionStatusProps {
  proposals: ClaimProposalDTO[];
}

export const TenantActionStatus: React.FC<TenantActionStatusProps> = ({ proposals }) => {
  const tenantActionState = getTenantActionState(proposals);

  return (
    <StatusContainer>
      <ResponseText>{tenantActionState.message}</ResponseText>
      <ExplanationText>{tenantActionState.explanation}</ExplanationText>
    </StatusContainer>
  );
};

export default TenantActionStatus;
