import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import storage from 'redux-persist/lib/storage';
import { PersistConfig, persistReducer } from 'redux-persist';

import enititiesReducer from './entities/entities.reducer';
import repositListReducer from './reposit-list/reposit-list.reducer';
import repositReducer from './reposit/reposit.reducer';
import authReducer from './auth/auth.reducer';
import systemReducer from './system/system.reducer';
import accountReducer from './account/account.reducer';
import loadingReducer from './loading/loading.reducer';
import errorReducer from './error/error.reducer';
import { LOGOUT } from './auth/auth.actions';
import { History } from 'history';
import flashMessagesReducer from './flash-messages/flash-messages.reducer';
import propertiesReducer from './property/property.reducer';
import addressReducer from './address/address.reducer';
import claimListReducer from './claim-list/claim-list.reducer';
import claimReducer from './claim/claim.reducer';
import userReducer from './user/user.reducer';
import inviteReducer from './invite/invite.reducer';
import claimItemReducer from './claim-item/claim-item.reducer';
import documentReducer from './document/document.reducer';
import landlordReducer from './landlord/landlord.reducer';
import referencingReducer from './referencing/referencing.reducer';
import userListReducer from './user-list/user-list.reducer';
import organizationListReducer from './organization-list/organization-list.reducer';
import referencingListReducer from './reference-list/reference-list.reducer';
import integratorReducer from './integrator/integrator.reducer';
import kpiDocumentReducer from './kpi-document/kpi-document.reducer';
import tenancyAnniversaryReducer from './tenancy-anniversary/tenancy-anniversary.reducer';
import modalReducer from './modal/modal.reducer';
import commissionReducer from './commission/commission.reducer';
import recipientReducer from './organization-recipient/organization-recipient.reducer';
import organizationBankAccountReducer from './organization-bank-account/organization-bank-account.reducer';
import guarantorReducer from './guarantor/guarantor.reducer';
import orderReducer from './order/order.reducer';
import reconciliationDocumentReducer from './reconciliation-document/reconciliation-document.reducer';
import internalTeamMemberReducer from './internal-team-member/internal-team-member.reducer';
import organizationInternalOwnerReducer from './organization-internal-owner/organization-internal-owner.reducer';
import organizationVerticalReducer from './organization-vertical/organization-vertical.reducer';

const appReducer = (history: History<any>) =>
  combineReducers({
    account: accountReducer,
    address: addressReducer,
    claim: claimReducer,
    claimItem: claimItemReducer,
    claimList: claimListReducer,
    auth: authReducer,
    entities: enititiesReducer,
    loading: loadingReducer,
    error: errorReducer,
    flashMessages: flashMessagesReducer,
    user: userReducer,
    invite: inviteReducer,
    properties: propertiesReducer,
    reposit: repositReducer,
    repositList: repositListReducer,
    system: systemReducer,
    router: connectRouter(history),
    referencing: referencingReducer,
    document: documentReducer,
    landlord: landlordReducer,
    userList: userListReducer,
    referencingList: referencingListReducer,
    organizationList: organizationListReducer,
    integrator: integratorReducer,
    kpiDocument: kpiDocumentReducer,
    tenancyAnniversaries: tenancyAnniversaryReducer,
    modal: modalReducer,
    commission: commissionReducer,
    recipients: recipientReducer,
    organizationBankAccounts: organizationBankAccountReducer,
    guarantor: guarantorReducer,
    order: orderReducer,
    reconciliationDocument: reconciliationDocumentReducer,
    internalTeamMember: internalTeamMemberReducer,
    organizationInternalOwner: organizationInternalOwnerReducer,
    organizationVertical: organizationVerticalReducer,
  });

export const rootReducer = (history: History, state: any, action: any) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return appReducer(history)(state, action);
};

export type AppState = ReturnType<typeof rootReducer>;

const wrappedWithHistoryReducer = (history: History) => (state: any, action: any) => rootReducer(history, state, action);

const persistConfig: PersistConfig<AppState> = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

export const getPersistedReducer = (history: History) => persistReducer(persistConfig, wrappedWithHistoryReducer(history));
