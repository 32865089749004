import { OrganizationVerticalDTOTypeEnum, OrganizationVerticalDTONameEnum } from '@reposit/api-client';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FullPageLoader } from '../../components/Loading';
import RepositCard from '../../components/Reposit/RepositCard';
import { Table, Tbody, Td, Tr } from '../../components/Table';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import {
  clearOrganizationVertical,
  fetchOrganizationVerticalRequested,
} from '../../redux/organization-vertical/organization-vertical.actions';
import { getPrimaryVertical, getSecondaryVerticals } from '../../redux/organization-vertical/organization-vertical.selectors';
import { FETCH_ORGANIZATION_USERS_STORE_KEY } from '../../redux/user/user.actions';

const PrimaryBadge = styled.div`
  background: ${(props) => props.theme.colors.primaryBrand};
  border-radius: 24px;
  display: inline-block;
  color: #fff;
  font-family: ${(props) => props.theme.typography.face.secondary};
  font-size: 12px;
  font-weight: bold;
  padding: 4px 12px;
  margin-left: auto;
`;

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const VerticalName = styled.span<{ isPrimary: boolean }>`
  font-weight: ${(props) => (props.isPrimary ? 'bold' : 'normal')};
`;

interface OrganizationVerticalsProps {
  organizationId: string;
}

const VerticalLabelMap = {
  [OrganizationVerticalDTONameEnum.INDEPENDENTSINGLE]: 'Independent Single',
  [OrganizationVerticalDTONameEnum.BTR]: 'Build to Rent',
  [OrganizationVerticalDTONameEnum.INDEPENDENTMULTI]: 'Independent Multi',
  [OrganizationVerticalDTONameEnum.FRANCHISE]: 'Franchise',
  [OrganizationVerticalDTONameEnum.CORPORATE]: 'Corporate',
  [OrganizationVerticalDTONameEnum.PRIVATELANDLORD]: 'Private Landlord',
  [OrganizationVerticalDTONameEnum.STUDENT]: 'Student',
  [OrganizationVerticalDTONameEnum.HMO]: 'HMO',
  [OrganizationVerticalDTONameEnum.PBSA]: 'PBSA',
};
const OrganizationVerticals: React.FC<OrganizationVerticalsProps> = ({ organizationId }) => {
  const dispatch = useDispatch();
  const primaryVertical = useSelector(getPrimaryVertical);
  const secondaryVerticals = useSelector(getSecondaryVerticals);
  const isFetchingLoadingSelector = createLoadingSelector([FETCH_ORGANIZATION_USERS_STORE_KEY]);
  const isOrgVerticalsLoading = useSelector(isFetchingLoadingSelector);
  useEffect(() => {
    return function cleanup() {
      dispatch(clearOrganizationVertical());
    };
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchOrganizationVerticalRequested({ organizationId }));
  }, [dispatch, organizationId]);

  if (isOrgVerticalsLoading) return <FullPageLoader />;
  const verticals = [...secondaryVerticals];
  if (primaryVertical) {
    verticals.unshift(primaryVertical);
  }

  const content = verticals.length ? (
    <Table>
      <Tbody>
        {verticals.map((vertical) => (
          <Tr key={vertical.name}>
            <Td>
              <VerticalName isPrimary={vertical.type === OrganizationVerticalDTOTypeEnum.PRIMARY}>
                {VerticalLabelMap[vertical.name]}
              </VerticalName>
            </Td>
            <Td style={{ width: '150px' }}>
              <BadgeContainer>
                {vertical.type === OrganizationVerticalDTOTypeEnum.PRIMARY && <PrimaryBadge>Primary</PrimaryBadge>}
              </BadgeContainer>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  ) : null;

  return (
    <Container>
      <Row>
        <Col sm={12}>
          <RepositCard
            title={verticals.length ? 'Verticals' : 'No verticals found for this organization'}
            tooltip="Some text about Organization Verticals"
            flush
          >
            {content}
          </RepositCard>
        </Col>
      </Row>
    </Container>
  );
};

export default OrganizationVerticals;
