import styled from "styled-components";

export const Table = styled.table<{ noBorder?: boolean }>`
  border-top: ${(props) => (props.noBorder ? 'none' : `1px solid ${props.theme.colors.dividerLight}`)};
  border-collapse: separate;
  border-spacing: 0 6px;
  width: 100%;
`;

export const Thead = styled.thead`
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: ${props => props.theme.colors.body};
`;

export const Tbody = styled.tbody`
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: ${props => props.theme.colors.body2};
`;

export const Th = styled.th<{ width?: number }>`
  font-weight: bold;
  height: 48px;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  text-align: left;

  &:first-child {
    padding: 0 0 0 40px;
  }

  &:last-child {
    padding: 0 20px 0 0;
  }
`;

export const Td = styled.td`
  height: 48px;
  vertical-align: middle;

  &:first-child {
    padding: 0 0 0 40px;
  }

  &:last-child {
    padding: 0 20px 0 0;
  }
`;

export const Tr = styled.tr`
  background: ${props => props.theme.colors.bgAccent};
`;
