import { schema, Schema } from 'normalizr';
import {
  AddressDTO,
  LandlordDTO,
  PropertyDTO,
  UserDTO,
  TenantDTO,
  OrderDTO,
  OrganizationDTO,
  TenancyDTO,
  DocumentDTO,
  TenancyOrderWithTenancyDTO,
  OrderCustomerDTO,
  OrderCustomerActionDTO,
  ClaimDTO,
  CheckoutDTO,
  ClaimItemDTO,
  OrganizationInviteDTO,
  ClaimDocumentDTO,
  ClaimItemDocumentDTO,
  ReferenceDTO,
  CheckDTO,
  IntegratorDTO,
  ArbitrationDTO,
  ArbitrationDocumentDTO,
  KpiDocumentDTO,
  TenancyAnniversaryWithTenancyDTO,
  OrganizationRecipientDTO,
  TenancyTransferDTO,
  TenancyOrderNoteDTO,
  OrganizationBankAccountDTO,
  ClaimProposalDTO,
  ClaimItemProposalDTO,
  OrganizationUserDTO,
  PaymentPlanDTO,
  PaymentPlanInstalmentDTO,
  ReconciliationDocumentDTO,
  InternalTeamMemberDTO,
  OrganizationInternalOwnerDTO,
  OrganizationVerticalDTO,
} from '@reposit/api-client';

export interface SchemaDefinition {
  address: Schema<AddressDTO>;
  document: Schema<DocumentDTO>;
  landlord: Schema<LandlordDTO>;
  property: Schema<PropertyDTO>;
  user: Schema<UserDTO>;
  users: Array<Schema<UserDTO>>;
  tenant: Schema<TenantDTO>;
  order: Schema<OrderDTO>;
  organization: Schema<OrganizationDTO>;
  organizationBankAccount: Schema<OrganizationBankAccountDTO>;
  organizationInvite: Schema<OrganizationInviteDTO>;
  organizationInvites: Array<Schema<OrganizationInviteDTO>>;
  organizationUser: Schema<OrganizationUserDTO>;
  organizationUsers: Array<Schema<OrganizationUserDTO>>;
  organizationInternalOwner: Schema<OrganizationInternalOwnerDTO>;
  organizationInternalOwners: Array<Schema<OrganizationInternalOwnerDTO>>;
  organizationRecipient: Schema<OrganizationRecipientDTO>;
  organizationRecipients: Array<Schema<OrganizationRecipientDTO>>;
  organizationBankAccounts: Array<Schema<OrganizationBankAccountDTO>>;
  tenancy: Schema<TenancyDTO>;
  tenancyOrder: Schema<TenancyOrderWithTenancyDTO>;
  tenancyAnniversary: Schema<TenancyAnniversaryWithTenancyDTO>;
  tenancies: Array<Schema<TenancyDTO>>;
  tenancyOrders: Array<Schema<TenancyOrderWithTenancyDTO>>;
  tenancyAnniversaries: Array<Schema<TenancyAnniversaryWithTenancyDTO>>;
  tenancyTransfer: Schema<TenancyTransferDTO>;
  tenancyOrderNote: Schema<TenancyOrderNoteDTO>;
  tenancyOrderNotes: Array<Schema<TenancyOrderNoteDTO>>;
  properties: Array<Schema<PropertyDTO>>;
  reference: Schema<ReferenceDTO>;
  references: Array<Schema<ReferenceDTO>>;
  addresses: Array<Schema<AddressDTO>>;
  orderCustomer: Schema<OrderCustomerDTO>;
  orderCustomerAction: Schema<OrderCustomerActionDTO>;
  claim: Schema<ClaimDTO>;
  check: Schema<CheckDTO>;
  checks: Array<Schema<CheckDTO>>;
  claims: Array<Schema<ClaimDTO>>;
  claimProposal: Schema<ClaimProposalDTO>;
  claimItemProposal: Schema<ClaimItemProposalDTO>;
  claimItem: Schema<ClaimItemDTO>;
  claimItemDocument: Schema<ClaimItemDocumentDTO>;
  checkout: Schema<CheckoutDTO>;
  claimDocument: Schema<ClaimDocumentDTO>;
  organizations: Array<Schema<OrganizationDTO>>;
  integrator: Schema<IntegratorDTO>;
  integrators: Array<Schema<IntegratorDTO>>;
  arbitration: Schema<ArbitrationDTO>;
  arbitrationDocument: Schema<ArbitrationDocumentDTO>;
  kpiDocument: Schema<KpiDocumentDTO>;
  kpiDocuments: Array<Schema<KpiDocumentDTO>>;
  paymentPlanInstalment: Schema<PaymentPlanInstalmentDTO>;
  paymentPlanInstalments: Array<Schema<PaymentPlanInstalmentDTO>>;
  paymentPlan: Schema<PaymentPlanDTO>;
  paymentPlans: Array<Schema<PaymentPlanDTO>>;
  reconciliationDocument: Schema<ReconciliationDocumentDTO>;
  reconciliationDocuments: Array<Schema<ReconciliationDocumentDTO>>;
  internalTeamMember: Schema<InternalTeamMemberDTO>;
  internalTeamMembers: Array<Schema<InternalTeamMemberDTO>>;
  organizationVertical: Schema<OrganizationVerticalDTO>;
  organizationVerticals: Array<Schema<OrganizationVerticalDTO>>;
}

const SCHEMA: SchemaDefinition = {
  address: {},
  document: {},
  landlord: {},
  property: {},
  user: {},
  users: [],
  tenant: {},
  order: {},
  organization: {},
  organizationBankAccount: {},
  organizationInvite: {},
  organizationInvites: [],
  organizationRecipient: {},
  organizationRecipients: [],
  organizationBankAccounts: [],
  organizationUser: {},
  organizationUsers: [],
  organizationInternalOwner: {},
  organizationInternalOwners: [],
  tenancy: {},
  claim: {},
  claimProposal: {},
  claimItemProposal: {},
  tenancies: [],
  tenancyOrder: {},
  tenancyOrders: [],
  tenancyOrderNote: {},
  tenancyOrderNotes: [],
  tenancyAnniversary: {},
  tenancyAnniversaries: [],
  tenancyTransfer: {},
  properties: [],
  addresses: [],
  orderCustomer: {},
  orderCustomerAction: {},
  claims: [],
  claimItem: {},
  checkout: {},
  claimDocument: {},
  claimItemDocument: {},
  reference: {},
  references: [],
  check: {},
  checks: [],
  organizations: [],
  integrator: {},
  integrators: [],
  arbitration: {},
  arbitrationDocument: {},
  kpiDocument: {},
  kpiDocuments: [],
  paymentPlan: {},
  paymentPlanInstalment: {},
  paymentPlans: [],
  paymentPlanInstalments: [],
  reconciliationDocument: {},
  reconciliationDocuments: [],
  internalTeamMember: {},
  internalTeamMembers: [],
  organizationVertical: {},
  organizationVerticals: [],
};

SCHEMA.internalTeamMember = new schema.Entity('internalTeamMember');

SCHEMA.internalTeamMembers = [SCHEMA.internalTeamMember];

SCHEMA.reference = new schema.Entity('reference');

SCHEMA.references = [SCHEMA.reference];

SCHEMA.check = new schema.Entity('check');

SCHEMA.checks = [SCHEMA.check];

SCHEMA.checkout = new schema.Entity('checkout');

SCHEMA.claim = new schema.Entity('claim');

SCHEMA.arbitration = new schema.Entity('arbitration');

SCHEMA.address = new schema.Entity('address');

SCHEMA.document = new schema.Entity('document');
SCHEMA.integrator = new schema.Entity('integrator');

SCHEMA.orderCustomer = new schema.Entity('orderCustomer');
SCHEMA.orderCustomerAction = new schema.Entity('orderCustomerAction', {
  orderCustomer: SCHEMA.orderCustomer,
});

SCHEMA.paymentPlan = new schema.Entity('paymentPlan');

SCHEMA.orderCustomer.define({
  actions: [SCHEMA.orderCustomerAction],
  paymentPlan: SCHEMA.paymentPlan,
});
SCHEMA.tenancy = new schema.Entity('tenancy');

SCHEMA.claimDocument = new schema.Entity('claimDocument', {
  claim: SCHEMA.claim,
  document: SCHEMA.document,
});

SCHEMA.claimItemDocument = new schema.Entity('claimItemDocument');

SCHEMA.claimItemProposal = new schema.Entity('claimItemProposal');

SCHEMA.claimItem = new schema.Entity('claimItem', {
  claim: SCHEMA.claim,
  claimItemDocuments: [SCHEMA.claimItemDocument],
});

SCHEMA.claimItemDocument.define({
  claimItem: SCHEMA.claimItem,
  document: SCHEMA.document,
  claimItemProposal: SCHEMA.claimItemProposal,
});

SCHEMA.organization = new schema.Entity('organization');

SCHEMA.organizations = [SCHEMA.organization];

SCHEMA.landlord = new schema.Entity('landlord', {
  address: SCHEMA.address,
  organization: SCHEMA.organization,
});

SCHEMA.property = new schema.Entity('property', {
  address: SCHEMA.address,
  landlord: SCHEMA.landlord,
  organization: SCHEMA.organization,
});

SCHEMA.order = new schema.Entity('order', {
  orderCustomers: [SCHEMA.orderCustomer],
});

SCHEMA.claimProposal = new schema.Entity('claimProposal', {
  claim: SCHEMA.claim,
  itemProposals: [SCHEMA.claimItemProposal],
});

SCHEMA.claimItemProposal.define({
  documents: [SCHEMA.claimItemDocument],
  claimProposal: SCHEMA.claimProposal,
});

SCHEMA.user = new schema.Entity('user', {
  organizations: [SCHEMA.organization],
});

SCHEMA.users = [SCHEMA.user];

SCHEMA.organizationInvite = new schema.Entity('organizationInvite', {
  organization: SCHEMA.organization,
});

SCHEMA.organizationInvites = [SCHEMA.organizationInvite];

SCHEMA.organizationUser = new schema.Entity('organizationUser', {
  organization: SCHEMA.organization,
  user: SCHEMA.user,
});

SCHEMA.organizationUsers = [SCHEMA.organizationUser];

SCHEMA.tenant = new schema.Entity('tenant', {
  user: SCHEMA.user,
});

SCHEMA.tenancyOrder = new schema.Entity('tenancyOrder');

SCHEMA.tenancyAnniversary = new schema.Entity('tenancyAnniversary', {
  tenancy: SCHEMA.tenancy,
  order: SCHEMA.order,
});

SCHEMA.tenancyOrderNote = new schema.Entity('tenancyOrderNote', {
  tenancyOrder: SCHEMA.tenancyOrder,
  user: SCHEMA.user,
});

SCHEMA.tenancyOrder.define({
  tenancy: SCHEMA.tenancy,
  order: SCHEMA.order,
  tenancyOrderNotes: [SCHEMA.tenancyOrderNote],
});

SCHEMA.arbitrationDocument = new schema.Entity('arbitrationDocument', {
  document: SCHEMA.document,
});

SCHEMA.arbitration.define({
  documents: [SCHEMA.arbitrationDocument],
  claim: SCHEMA.claim,
  repaymentOrder: SCHEMA.order,
});

SCHEMA.checkout.define({
  tenancy: SCHEMA.tenancy,
  claim: SCHEMA.claim,
});

SCHEMA.claim.define({
  order: SCHEMA.order,
  checkout: SCHEMA.checkout,
  items: [SCHEMA.claimItem],
  documents: [SCHEMA.claimDocument],
  arbitration: SCHEMA.arbitration,
  proposals: [SCHEMA.claimProposal],
});

SCHEMA.tenancyTransfer = new schema.Entity('tenancyTransfer', {
  previousOrganization: SCHEMA.organization,
  newOrganization: SCHEMA.organization,
});

SCHEMA.tenancy.define({
  orders: [SCHEMA.tenancyOrder],
  documents: [SCHEMA.document],
  property: SCHEMA.property,
  tenants: [SCHEMA.tenant],
  checkout: SCHEMA.checkout,
  tenancyTransfers: [SCHEMA.tenancyTransfer],
});

SCHEMA.kpiDocument = new schema.Entity('kpiDocument', {
  document: SCHEMA.document,
});

SCHEMA.reconciliationDocument = new schema.Entity('reconciliationDocument', {
  document: SCHEMA.document,
});

SCHEMA.organizationRecipient = new schema.Entity('organizationRecipient');

SCHEMA.organizationBankAccount = new schema.Entity('organizationBankAccount');

SCHEMA.organizationInternalOwner = new schema.Entity('organizationInternalOwner');

SCHEMA.organizationVertical = new schema.Entity('organizationVertical');

SCHEMA.organization.define({
  organizationRecipients: [SCHEMA.organizationRecipient],
  organizationBankAccounts: [SCHEMA.organizationBankAccount],
  organizationInternalOwners: [SCHEMA.organizationInternalOwner],
  organizationVerticals: [SCHEMA.organizationVertical],
});

SCHEMA.organizationRecipients = [SCHEMA.organizationRecipient];
SCHEMA.organizationBankAccounts = [SCHEMA.organizationBankAccount];
SCHEMA.organizationInternalOwners = [SCHEMA.organizationInternalOwner];
SCHEMA.organizationVerticals = [SCHEMA.organizationVertical];
SCHEMA.tenancies = [SCHEMA.tenancy];
SCHEMA.tenancyOrders = [SCHEMA.tenancyOrder];
SCHEMA.tenancyAnniversaries = [SCHEMA.tenancyAnniversary];
SCHEMA.tenancyOrderNotes = [SCHEMA.tenancyOrderNote];
SCHEMA.properties = [SCHEMA.property];
SCHEMA.addresses = [SCHEMA.address];
SCHEMA.claims = [SCHEMA.claim];
SCHEMA.integrators = [SCHEMA.integrator];
SCHEMA.kpiDocuments = [SCHEMA.kpiDocument];
SCHEMA.reconciliationDocuments = [SCHEMA.reconciliationDocument];
SCHEMA.paymentPlanInstalment = new schema.Entity('paymentPlanInstalment');
SCHEMA.paymentPlanInstalments = [SCHEMA.paymentPlanInstalment];
SCHEMA.paymentPlan.define({
  orderCustomer: SCHEMA.orderCustomer,
  instalments: [SCHEMA.paymentPlanInstalment],
});
SCHEMA.paymentPlans = [SCHEMA.paymentPlan];


export default SCHEMA;
