import { OrganizationsApi, OrganizationVerticalDTO } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import { createOrganizationsApi, runSagaWithAuth } from '../utils/api.utils';
import {
  fetchOrganizationVerticalFailed,
  fetchOrganizationVerticalRequested,
  fetchOrganizationVerticalSuccess,
} from './organization-vertical.actions';

export function* fetchOrganizationVerticals({ payload }: { payload: { organizationId: string }; type: string }) {
  try {
    const organizationsApi: OrganizationsApi = yield createOrganizationsApi();
    const organizationId = payload.organizationId;
    const apiResponse: AxiosResponse<OrganizationVerticalDTO[]> = yield call(
      runSagaWithAuth(() => organizationsApi.getOrganizationVerticals(organizationId))
    );
    const organizationVerticalIds: string[] = yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.organizationVerticals);
    yield put(fetchOrganizationVerticalSuccess(organizationVerticalIds));
  } catch (e) {
    yield put(fetchOrganizationVerticalFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchOrganizationVerticalSagas() {
  yield takeLatest(fetchOrganizationVerticalRequested.type, fetchOrganizationVerticals);
}
