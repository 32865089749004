import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { Label } from '../../Typography/index';
import { RoundButton } from '../../Button';
import HelpIcon from '../../../assets/svg/help.svg';
import TickIcon from '../../../assets/svg/tick.svg';
interface CheckboxProps {
  name: string;
  label: string;
  isChecked: boolean;
  helpText?: string;
  onChange?: (e: ChangeEvent<any>) => void;
  isDisabled?: boolean;
}

const StyledWrapper = styled.div`
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }
`;

const Checkbox = styled(Label)<{ isChecked: boolean; isDisabled: boolean }>`
  align-items: flex-start;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  display: flex;
  font-size: 1em;
  opacity: ${(props) => (props.isDisabled ? 0.6 : 1)};

  &:hover {
    text-decoration: ${(props) => (props.isDisabled ? 'none' : 'underline')};

    &:before {
      background-color: ${(props) => (props.isDisabled ? 'transparent' : '#f1f1f1')};
    }
  }

  span {
    display: flex;
  }

  &:before {
    background: #fff ${(props) => (props.isChecked ? `url(${TickIcon}) no-repeat center center` : ``)};
    background-size: 12px;
    box-sizing: border-box;
    border: ${(props) =>
      props.isDisabled
        ? `2px solid ${props.theme.colors.disabled}`
        : props.isChecked
        ? `2px solid ${props.theme.colors.primaryBrand}`
        : `2px solid ${props.theme.colors.disabled}`};
    content: '';
    display: block;
    flex: 0 0 18px;
    margin: 2px 12px 0 0;
    height: 18px;
    width: 18px;
  }
`;

const HelpButton = styled(RoundButton)`
  margin: 0 12px;
`;

const LabelText = styled.div``;

export default ({ name, label, isChecked, helpText, onChange, isDisabled = false }: CheckboxProps): JSX.Element => {
  return (
    <StyledWrapper>
      <Checkbox isChecked={isChecked} htmlFor={name} isDisabled={isDisabled}>
        <input id={name} name={name} type="checkbox" onChange={onChange} disabled={isDisabled} />
        <span>
          <LabelText>{label}</LabelText> {helpText && <HelpButton icon={HelpIcon} data-tip={helpText} />}
        </span>
      </Checkbox>
    </StyledWrapper>
  );
};
