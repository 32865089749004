import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createErrorMessageSelector } from '../redux/error/error.selector';
import {
  getInternalTeamMembersRequested,
  GET_INTERNAL_TEAM_MEMBERS_STORE_KEY,
} from '../redux/internal-team-member/internal-team-member.actions';
import { getInternalTeamMembers } from '../redux/internal-team-member/internal-team-member.selectors';
import { createLoadingSelector } from '../redux/loading/loading.selector';
import { InternalTeamMemberDTOTeamEnum } from '@reposit/api-client';

export const useInternalTeamMembers = (team: InternalTeamMemberDTOTeamEnum) => {
  const dispatch = useDispatch();
  const loadingSelector = createLoadingSelector([GET_INTERNAL_TEAM_MEMBERS_STORE_KEY]);
  const errorSelector = createErrorMessageSelector([GET_INTERNAL_TEAM_MEMBERS_STORE_KEY]);
  const isLoading = useSelector(loadingSelector);
  const error = useSelector(errorSelector);
  const internalTeamMembers = useSelector(getInternalTeamMembers);

  useEffect(() => {
    dispatch(getInternalTeamMembersRequested());
  }, [dispatch]);

  return {
    isLoading,
    internalTeamMembers,
    error,
  };
};
