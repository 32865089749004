import { unwrapResult } from '@reduxjs/toolkit';
import {
  ClaimWithCheckoutRelationsDTO,
  ClaimWithCheckoutRelationsDTOStatusEnum,
  PaymentPlanDTOStatusEnum,
  UserDTOInternalRolesEnum,
} from '@reposit/api-client';
import { Formik } from 'formik';
import { get, uniqBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useAppDispatch } from '../../..';
import poundIcon from '../../../assets/svg/pound-sterling.svg';
import { RepositTheme } from '../../../base/theme';
import Button from '../../../components/Button';
import ClaimArbitration from '../../../components/ClaimArbitration';
import ClaimDetails from '../../../components/ClaimDetails';
import ClaimDocuments from '../../../components/ClaimDocuments';
import ClaimItems from '../../../components/ClaimItems';
import { MediationClaimItems } from '../../../components/ClaimItems/MediationClaimItems';
import DisputeInformation from '../../../components/ClaimResponse';
import ClaimReview from '../../../components/ClaimReview';
import { ClaimStatuses } from '../../../components/ClaimStatuses';
import { ConfirmationModalProps, NotificationInfo, useConfirmationModal } from '../../../components/ConfirmationModal';
import DeadlinesCard from '../../../components/DeadlinesCard';
import DeletePaymentPlanModal from '../../../components/DeletePaymentPlanModal';
import DownloadClaimDocumentsModal from '../../../components/DownloadClaimDocumentsModal';
import FlashMessage from '../../../components/FlashMessage';
import { Input } from '../../../components/FormFields';
import DatePicker from '../../../components/FormFields/DatePicker';
import FieldWithLabel from '../../../components/FormFields/FieldWithLabel';
import InventoryDetails from '../../../components/InventoryDetails';
import Loading from '../../../components/Loading';
import Modal from '../../../components/Modal';
import { PaymentPlan } from '../../../components/PaymentPlan/payment-plan';
import PaymentPlanModal, { HISTORIC_PAYMENT_PLAN, PaymentPlanFormValues } from '../../../components/PaymentPlanModal';
import { ReopenModal, ReopenType } from '../../../components/ReopenModal';
import RepositCard from '../../../components/Reposit/RepositCard';
import { Caption, Header2, Header3, P1 } from '../../../components/Typography';
import WaiveClaimDisputeFeeModal from '../../../components/WaiveClaimDisputeFeeModal';
import { DATE_FORMAT } from '../../../constants/dates';
import { hasInternalRole } from '../../../redux/account/account.selectors';
import {
  createArbitrationApiRequested,
  CREATE_ARBITRATION_STORE_KEY,
  deactivateClaimApiRequested,
  DEACTIVATE_CLAIM_STORE_KEY,
  downloadDocumentsApiRequested,
  DOWNLOAD_DOCUMENTS_STORE_KEY,
  fetchClaimRequested,
  FETCH_CLAIM_STORE_KEY,
  reopenClaimApiRequested,
  REOPEN_CLAIM_STORE_KEY,
  resolveClaimApiRequested,
  RESOLVE_CLAIM_STORE_KEY,
  setCurrentClaimActionModal,
  setPaidAtApiRequested,
  SET_PAID_AT_CLAIM_STORE_KEY,
  updateAutoChargeApiRequested,
  UPDATE_AUTO_CHARGE_STORE_KEY,
  WAIVE_CLAIM_DISPUTE_FEE_STORE_KEY,
  GENERATE_ADR_FORM_STORE_KEY,
} from '../../../redux/claim/claim.actions';
import { getClaimHasBeenFetched } from '../../../redux/claim/claim.selector';
import { waiveClaimDisputeFeeThunk, generateADRFormThunk } from '../../../redux/claim/claim.thunk';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import { clearAmountRemaining } from '../../../redux/order/order.action';
import { getOrderAmountRemaining } from '../../../redux/order/order.selector';
import { fetchAmountRemainingForPaymentPlanOrderThunk } from '../../../redux/order/order.thunk';
import {
  getManualPaymentsByClaimId,
  getPaymentPlanByClaimId,
  getPaymentPlanCustomerByClaimId,
  getPendingPaymentPlanOrder,
} from '../../../redux/payment-plan/payment-plan.selectors';
import { createPaymentPlanThunk, deletePaymentPlanThunk } from '../../../redux/payment-plan/payment-plan.thunk';
import { AppState } from '../../../redux/root.reducer';
import { getFullClaimById } from '../../../redux/selectors/claim.selectors';
import {
  getIsAgentDeciding,
  getIsTenantDeciding,
  getMediationClaimItemsByClaimId,
} from '../../../redux/selectors/mediation.selectors';
import { getTenancyInventoryByClaimId } from '../../../redux/tenancy-inventory/tenancy-inventory.selectors';
import { getClaimStatus, getDisplayAddress, getLandlordFullName } from '../../../utils/common.utils';
import { twoDPRegex } from '../../../utils/regex/number.regex';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../../FlashMessage';

interface ClaimViewProps {}

interface ClaimViewParams {
  id: string;
}

const ActionBar = styled.div<{ flexStart?: boolean }>`
  display: flex;
  padding: 24px 0 0;
  justify-content: flex-end;
  flex-wrap: wrap;

  ${(props) =>
    props.flexStart &&
    `
    justify-content: flex-start;
  `}

  button {
    margin-bottom: 10px;
    margin-right: 10px;
  }
`;

const ClaimStatus = styled.div`
  background: ${(props) => props.theme.colors.positive};
  border-radius: 24px;
  display: inline-block;
  color: #fff;
  font-family: ${(props) => props.theme.typography.face.secondary};
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 0 auto;
  padding: 4px 20px;

  &.ACTIVE {
    background: ${(props) => props.theme.colors.positive};
  }

  &.REJECTED {
    background: ${(props) => props.theme.colors.negative};
  }

  &.AWAITING_RESPONSE {
    background: ${(props) => props.theme.colors.placeholder};
  }

  &.DEACTIVATED {
    background: ${(props) => props.theme.colors.negative};
  }

  &.ARBITRATED {
    background: ${(props) => props.theme.colors.warning};
  }
`;

const UploaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UploaderDocumentName = styled.span`
  font-family: ${RepositTheme.typography.face.secondary};
  font-size: 12px;
  margin-left: 7px;
`;

const BrowseLabel = styled.label`
  background: #e0ecf7;
  border-color: #e0ecf7;
  height: 24px;
  width: 85px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: ${(props) => props.theme.colors.primaryBrand};
  font-family: ${RepositTheme.typography.face.secondary};
  cursor: pointer;
`;

const InputFile = styled.input.attrs({
  type: 'file',
})`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

const BetaBadge = styled.div`
  background: ${(props) => props.theme.colors.warning};
  border-radius: 24px;
  display: inline-block;
  color: #fff;
  font-family: ${(props) => props.theme.typography.face.secondary};
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 0 auto;
  padding: 4px 20px;
  margin-bottom: 6px;
`;

const ARBITRATION_VALIDATION_SCHEMA = Yup.object().shape({
  arbitratedAmount: Yup.number()
    .typeError('Amount must be a number')
    .required('Required')
    .test('two-decimals', 'Amount must have a maximum of two decimal places', (value) => {
      return twoDPRegex.test(value);
    }),
});

const ClaimView: React.FC<ClaimViewProps> = (props) => {
  const dispatch = useAppDispatch();
  const { id } = useParams<ClaimViewParams>();
  const [standardModalState, setStandardModalState] = useState<ConfirmationModalProps>({} as ConfirmationModalProps);
  const [arbitrationFile, setArbitrationFile] = useState<File>();
  const loadingSetPaidAt = useSelector(createLoadingSelector([SET_PAID_AT_CLAIM_STORE_KEY]));
  const loadingCreateArbitration = useSelector(createLoadingSelector([CREATE_ARBITRATION_STORE_KEY]));
  const fetchClaim = useCallback(() => dispatch(fetchClaimRequested({ claimId: id })), [id, dispatch]);
  const isFetchClaimLoading = useSelector(createLoadingSelector([FETCH_CLAIM_STORE_KEY]));
  const hasClaimFetched = useSelector(getClaimHasBeenFetched);
  const claim = useSelector((app: AppState) => getFullClaimById(app, id));
  // this is looking for a pending payment plan order specifically
  const pendingOrderForPaymentPlan = useSelector(getPendingPaymentPlanOrder(id));
  const ppOrderCustomers = get(pendingOrderForPaymentPlan, 'orderCustomers', []);
  const paymentPlan = useSelector(getPaymentPlanByClaimId(id));

  const orderManualPayments = useSelector(getManualPaymentsByClaimId(id));
  const orderManualPaymentsTotal = orderManualPayments.reduce((acc, curr) => acc + curr.amount, 0);

  const paymentPlanCustomer = useSelector(getPaymentPlanCustomerByClaimId(id));
  const ppOrderAmountRemaining = useSelector(getOrderAmountRemaining);

  const tenancyInventory = useSelector(getTenancyInventoryByClaimId(id));

  const isLegacyClaim = !(claim && claim.mediationEnabled);
  const autoChargeEnabled = get(claim, 'autoChargeEnabled', true);
  const currentClaimModal = useSelector((state: AppState) => state.claim.currentClaimActionModal);
  const { renderConfirmationModal: renderStandardConfirmationModal, showModal: showStandardModal } =
    useConfirmationModal(standardModalState);
  const [globalFlashMessagePayload, onDismissGlobalFlashMessage] = useFlashMessage([
    REOPEN_CLAIM_STORE_KEY,
    DOWNLOAD_DOCUMENTS_STORE_KEY,
    CREATE_ARBITRATION_STORE_KEY,
    WAIVE_CLAIM_DISPUTE_FEE_STORE_KEY,
    GENERATE_ADR_FORM_STORE_KEY,
  ]);

  const [createArbitrationFlashMessagePayload, onDismissCreateArbitrationFlashMessage] = useFlashMessage([
    CREATE_ARBITRATION_STORE_KEY,
  ]);

  const mediationClaimItems = useSelector((state: AppState) => getMediationClaimItemsByClaimId(state, get(claim, 'id', '')));

  const deletedItems = claim && claim.items.filter((item) => !!item.deletedAt);
  const activeItems = claim && claim.items.filter((item) => !item.deletedAt);
  const activeMediationItems = mediationClaimItems.filter((i) => !i.deletedAt);

  const mediationEnabled = !!(claim && claim.mediationEnabled);

  const paymentPlanIsComplete = paymentPlan && paymentPlan.status === PaymentPlanDTOStatusEnum.COMPLETE;

  const isCloseButtonVisible = useCallback(() => {
    if (
      (!paymentPlan || paymentPlanIsComplete) &&
      (claim.status === ClaimWithCheckoutRelationsDTOStatusEnum.DRAFT ||
        claim.status === ClaimWithCheckoutRelationsDTOStatusEnum.AWAITINGREVIEW ||
        claim.status === ClaimWithCheckoutRelationsDTOStatusEnum.AWAITINGADDITIONALINFORMATION ||
        claim.status === ClaimWithCheckoutRelationsDTOStatusEnum.AWAITINGRESPONDENTS)
    ) {
      return true;
    }

    return false;
  }, [claim, paymentPlan, paymentPlanIsComplete]);

  const isResolveButtonVisible = useCallback(() => {
    if (
      (!paymentPlan || paymentPlanIsComplete) &&
      claim.status !== ClaimWithCheckoutRelationsDTOStatusEnum.RESOLVED &&
      claim.status !== ClaimWithCheckoutRelationsDTOStatusEnum.DEACTIVATED
    ) {
      return true;
    }
    return false;
  }, [claim, paymentPlan, paymentPlanIsComplete]);

  const isADRFormButtonVisible = useCallback(() => {
    if (
      (claim.status === ClaimWithCheckoutRelationsDTOStatusEnum.ARBITRATION ||
        claim.status === ClaimWithCheckoutRelationsDTOStatusEnum.AWAITINGARBITRATION) &&
      !claim.arbitration
    ) {
      return true;
    }
    return false;
  }, [claim]);

  const isCreateArbitrationButtonVisible = useCallback(() => {
    if (
      (claim.status === ClaimWithCheckoutRelationsDTOStatusEnum.ARBITRATION ||
        claim.status === ClaimWithCheckoutRelationsDTOStatusEnum.AWAITINGARBITRATION) &&
      !claim.arbitration
    ) {
      return true;
    }
    return false;
  }, [claim]);

  const isWaiveDisputeFeeButtonVisible = useCallback(() => {
    // Should this just appear all the time?
    if (claim.status === ClaimWithCheckoutRelationsDTOStatusEnum.AWAITINGARBITRATIONADMINFEE) {
      return true;
    }
    return false;
  }, [claim]);

  const isReopenButtonVisible = useCallback(() => {
    if (claim.status === ClaimWithCheckoutRelationsDTOStatusEnum.DEACTIVATED) {
      return true;
    }
    return false;
  }, [claim]);

  const handleResolveClaim = useCallback(
    (reason?: string, notification?: NotificationInfo) => {
      if (id && reason && notification) {
        dispatch(
          resolveClaimApiRequested({
            claimId: id,
            reason,
            sendTenantNotifications: notification.sendTenantNotification,
            sendSupplierNotifications: notification.sendSupplierNotification,
          })
        );
      }
    },
    [id, dispatch]
  );

  const handleCloseClaim = useCallback(
    (reason?: string, notification?: NotificationInfo) => {
      if (id && reason && notification) {
        dispatch(
          deactivateClaimApiRequested({
            claimId: id,
            reason,
            sendTenantNotifications: notification.sendTenantNotification,
            sendSupplierNotifications: notification.sendSupplierNotification,
          })
        );
      }
    },
    [id, dispatch]
  );

  const handleSetPaid = (values: { paidAt?: Date }) => {
    if (values.paidAt) {
      dispatch(setPaidAtApiRequested({ claimId: id, paidAt: values.paidAt.toString() }));
    }
  };

  const handleCreateArbitration = (values: { arbitratedAmount?: number }) => {
    if (values.arbitratedAmount || values.arbitratedAmount === 0) {
      dispatch(
        createArbitrationApiRequested({
          claimId: id,
          arbitratedAmount: Math.round(Number(values.arbitratedAmount) * 100),
          file: arbitrationFile,
        })
      );
    }
  };

  const toggleUpdateAutoCharge = useCallback(() => {
    dispatch(updateAutoChargeApiRequested({ claimId: id, autoChargeEnabled: !autoChargeEnabled }));
  }, [autoChargeEnabled, id, dispatch]);

  // leaving here
  // const downloadDocumentsLocal = useCallback(() => {
  //   appDispatch(setCurrentClaimActionModal(''));
  //   if (!isBeingDownloaded) {
  //     appDispatch(startCurrentClaimZipDownload(id));
  //     return appDispatch(downloadDocumentsLocally(id));
  //   }
  // }, [id, appDispatch, isBeingDownloaded]);

  useEffect(() => {
    fetchClaim();
  }, [id, fetchClaim]);

  useEffect(() => {
    if (pendingOrderForPaymentPlan && !ppOrderAmountRemaining) {
      dispatch(fetchAmountRemainingForPaymentPlanOrderThunk({ orderId: pendingOrderForPaymentPlan.id }));
    }
    return function cleanUp() {
      if (ppOrderAmountRemaining) {
        dispatch(clearAmountRemaining());
      }
    };
  }, [pendingOrderForPaymentPlan, dispatch, ppOrderAmountRemaining]);

  useEffect(() => {
    if (!currentClaimModal) {
      return showStandardModal(false);
    }

    if (currentClaimModal === 'resolve') {
      setStandardModalState({
        onSubmit: (reason?: string, notification?: NotificationInfo) => handleResolveClaim(reason, notification),
        text: 'Are you sure you would like to resolve this Claim?',
        title: 'Resolve Claim!',
        enableDetailsField: true,
        enableNotificationsFields: true,
        loadingActions: [RESOLVE_CLAIM_STORE_KEY],
        onDismiss: () => dispatch(setCurrentClaimActionModal('')),
      });

      showStandardModal(true);
    } else if (currentClaimModal === 'close') {
      setStandardModalState({
        onSubmit: (reason?: string, notification?: NotificationInfo) => handleCloseClaim(reason, notification),
        text: 'Are you sure you would like to close this Claim?',
        title: 'Close Claim!',
        enableDetailsField: true,
        enableNotificationsFields: true,
        loadingActions: [DEACTIVATE_CLAIM_STORE_KEY],
        onDismiss: () => dispatch(setCurrentClaimActionModal('')),
      });

      showStandardModal(true);
    } else if (currentClaimModal === 'autoCharge') {
      setStandardModalState({
        onSubmit: toggleUpdateAutoCharge,
        text: 'Are you sure you would like to modify the auto charge status?',
        title: autoChargeEnabled ? 'Disable Auto Charge' : 'Enable Auto Charge',
        loadingActions: [UPDATE_AUTO_CHARGE_STORE_KEY],
        onDismiss: () => dispatch(setCurrentClaimActionModal('')),
      });

      showStandardModal(true);
    } else if (currentClaimModal === 'downloadDocuments') {
      showStandardModal(false);
    } else if (currentClaimModal === 'createPaymentPlan') {
      showStandardModal(false);
    } else if (currentClaimModal === 'deletePaymentPlan') {
      showStandardModal(false);
    }
  }, [
    currentClaimModal,
    showStandardModal,
    handleResolveClaim,
    handleCloseClaim,
    dispatch,
    toggleUpdateAutoCharge,
    autoChargeEnabled,
  ]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setArbitrationFile(event.target.files[0]);
    }
  };

  const isSubmittingReopenSelector = createLoadingSelector([REOPEN_CLAIM_STORE_KEY]);
  const isSubmittingReopen = useSelector(isSubmittingReopenSelector);

  const isAgentDeciding = useSelector((state: AppState) => getIsAgentDeciding(state, get(claim, 'id', '')));
  const isTenantDeciding = useSelector((state: AppState) => getIsTenantDeciding(state, get(claim, 'id', '')));

  const isDownloadingClaimDocumentsSelector = createLoadingSelector([DOWNLOAD_DOCUMENTS_STORE_KEY]);
  const isDownloadingClaimDocuments = useSelector(isDownloadingClaimDocumentsSelector);

  const hasCreatePaymentPlanRole = useSelector((state: AppState) =>
    hasInternalRole(state, UserDTOInternalRolesEnum.PAYMENTPLANCREATE)
  );

  const hasDeletePaymentPlanRole = useSelector((state: AppState) =>
    hasInternalRole(state, UserDTOInternalRolesEnum.PAYMENTPLANDELETE)
  );

  const hasDeletablePaymentPlanStatus =
    paymentPlan &&
    (paymentPlan.status === PaymentPlanDTOStatusEnum.CANCELLED ||
      paymentPlan.status === PaymentPlanDTOStatusEnum.PENDING ||
      paymentPlan.status === PaymentPlanDTOStatusEnum.DEFAULT);

  const [isPPSubmitting, setIsPPSubmitting] = useState(false);
  const [isDeletePPSubmitting, setIsDeletePPSubmitting] = useState(false);
  const [isCreatingADRForm, setIsCreatingADRForm] = useState(false);

  const [isFeeBeingWaived, setIsFeeBeingWaived] = useState(false);

  const renderClaimDetails = (claim: ClaimWithCheckoutRelationsDTO, isAgentDeciding: boolean, isTenantDeciding: boolean) => {
    const { checkout, repositMessage, notes, details, documents, agentResponse, status, proposals } = claim;

    const { tenancy } = checkout;
    const { orders, property, tenants } = tenancy;
    const { landlord, address } = property;
    const tenancyOrder = orders && orders.length ? orders[0] : undefined;
    const autoChargeButtonMessage = `${autoChargeEnabled ? 'Disable' : 'Enable'} Auto Charge`;
    // when merging the statuses in, it can create duplicates
    // easier to fix here
    const uniqueStatuses = uniqBy(claim.statuses, 'id');

    const createPaymentPlan = async (val: PaymentPlanFormValues) => {
      setIsPPSubmitting(true);
      return dispatch(
        createPaymentPlanThunk({
          orderCustomerId: val.orderCustomerId,
          // to pence
          amountPerInstalment: Math.round(val.amount * 100),
          // this needs to be optional in the previous component for some reason
          startDate: val.startDate as Date,
          claimId: id,
          manualPaymentsTotal: val.manualPaymentsTotal ? Math.round(val.manualPaymentsTotal * 100) : undefined,
          isHistoric: val.isHistoric === HISTORIC_PAYMENT_PLAN.YES ? true : false,
          interestAmount: val.interestAmount ? Math.round(val.interestAmount * 100) : undefined,
        })
      )
        .then(unwrapResult)
        .then(() => {
          dispatch(setCurrentClaimActionModal(''));
        })
        .finally(() => setIsPPSubmitting(false));
    };

    const deletePaymentPlan = async () => {
      setIsDeletePPSubmitting(true);
      // have to do this because of the types
      const paymentPlanId = paymentPlan ? paymentPlan.id : '';
      return dispatch(deletePaymentPlanThunk({ claimId: claim.id, paymentPlanId }))
        .then(() => {
          dispatch(setCurrentClaimActionModal(''));
        })
        .finally(() => setIsDeletePPSubmitting(false));
    };

    const waiveClaimDisputeFee = async () => {
      setIsFeeBeingWaived(true);
      return dispatch(waiveClaimDisputeFeeThunk({ claimId: claim.id }))
        .then(unwrapResult)
        .then(() => {
          dispatch(setCurrentClaimActionModal(''));
        })
        .finally(() => setIsPPSubmitting(false));
    };

    const createADRForm = async () => {
      setIsCreatingADRForm(true);
      return dispatch(generateADRFormThunk({ claimId: claim.id })).then(() => setIsCreatingADRForm(false));
    };

    const paymentPlanTenant =
      tenants && tenants.find((t) => (paymentPlanCustomer ? t.userId === paymentPlanCustomer.userId : false));
    return (
      <>
        {isLegacyClaim ? (
          <Container>
            <Row>
              <Col sm={12}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <BetaBadge>LEGACY CLAIM</BetaBadge>
                </div>
              </Col>
            </Row>
          </Container>
        ) : null}
        <Row>
          <Col lg={12}>
            <Header2>Claim</Header2>
            <Caption>{getDisplayAddress(address)}</Caption>
            <ClaimStatus className={status}>{getClaimStatus(claim, isAgentDeciding, isTenantDeciding)}</ClaimStatus>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <ActionBar flexStart>
              {isCloseButtonVisible() && (
                <Button onClick={() => dispatch(setCurrentClaimActionModal('close'))} buttonType="warning">
                  Close
                </Button>
              )}
              {isResolveButtonVisible() && (
                <Button onClick={() => dispatch(setCurrentClaimActionModal('resolve'))}>Resolve</Button>
              )}
              <Button onClick={() => dispatch(setCurrentClaimActionModal('setPaidAt'))} disabled={!!claim.supplierPaidAt}>
                Agent Paid
              </Button>
              <Button onClick={() => dispatch(setCurrentClaimActionModal('autoCharge'))}>{autoChargeButtonMessage}</Button>
              {isCreateArbitrationButtonVisible() && (
                <Button onClick={() => dispatch(setCurrentClaimActionModal('createArbitration'))}>Create Arbitration</Button>
              )}
              {isWaiveDisputeFeeButtonVisible() ? (
                <Button onClick={() => dispatch(setCurrentClaimActionModal('waiveDisputeFee'))}>Waive Dispute Fee</Button>
              ) : null}
              {isReopenButtonVisible() && (
                <Button onClick={() => dispatch(setCurrentClaimActionModal('reopenClaim'))}>Reopen Claim</Button>
              )}
              <Button onClick={() => dispatch(setCurrentClaimActionModal('downloadDocuments'))}>Download Documents</Button>
              {isADRFormButtonVisible() && (
                <Button disabled={isCreatingADRForm} onClick={() => createADRForm()}>
                  Generate ADR Form
                </Button>
              )}
              {/* Leaving here */}
              {/* <Button
                onClick={() => dispatch(setCurrentClaimActionModal('downloadLocalDocuments'))}
                disabled={!!isBeingDownloaded}
              >
                Download Documents Locally (NEW)
              </Button> */}
              {!paymentPlan &&
                claim.status !== ClaimWithCheckoutRelationsDTOStatusEnum.RESOLVED &&
                !!hasCreatePaymentPlanRole && (
                  <Button onClick={() => dispatch(setCurrentClaimActionModal('createPaymentPlan'))}>Create Payment Plan</Button>
                )}
              {paymentPlan && hasDeletablePaymentPlanStatus && hasDeletePaymentPlanRole && (
                <Button onClick={() => dispatch(setCurrentClaimActionModal('deletePaymentPlan'))}>Delete Payment Plan</Button>
              )}
            </ActionBar>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            {globalFlashMessagePayload ? (
              <>
                <br />
                <FlashMessage
                  onDismiss={onDismissGlobalFlashMessage}
                  timeRemaining={FLASH_MESSAGE_TIMEOUT}
                  payload={globalFlashMessagePayload}
                />
              </>
            ) : undefined}
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            {status === ClaimWithCheckoutRelationsDTOStatusEnum.AWAITINGREVIEW ? (
              <ClaimReview claimId={id} canSendMessage={!repositMessage} />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <ClaimDetails claim={claim} />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            {paymentPlan && paymentPlanTenant ? (
              <PaymentPlan
                paymentPlan={paymentPlan}
                paymentPlanTenant={paymentPlanTenant}
                manualPaymentsTotal={orderManualPaymentsTotal}
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <ClaimStatuses statuses={uniqueStatuses} />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <DeadlinesCard title="Claim deadlines" claim={claim} tenancyOutcomeDeadline={tenancy.outcomeDeadline} />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <RepositCard title="Reposit" tooltip="lorem">
              {tenancyOrder && (
                <>
                  <P1>{tenancyOrder.id}</P1>
                  <NavLink to={`/reposits/${tenancyOrder.id}`}>
                    <Button buttonType="secondary">View Reposit</Button>
                  </NavLink>
                </>
              )}
            </RepositCard>
          </Col>
          <Col lg={6}>
            <RepositCard title="Landlord" tooltip="lorem">
              {landlord && (
                <>
                  <P1>{getLandlordFullName(landlord)}</P1>
                  <NavLink to={`/landlords/${landlord.id}`} style={{ textDecoration: 'none' }}>
                    <Button buttonType="secondary" noArrow={true}>
                      View Landlord
                    </Button>
                  </NavLink>
                </>
              )}
            </RepositCard>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            {mediationEnabled ? (
              <MediationClaimItems claimItems={activeMediationItems} proposals={proposals} />
            ) : (
              <ClaimItems title="Active Claim Items" showDeletedAt={false} items={activeItems} proposals={proposals} />
            )}
          </Col>
        </Row>
        {!!deletedItems.length && (
          <Row>
            <Col lg={12}>
              <ClaimItems title="Deleted Claim Items" showDeletedAt={true} items={deletedItems} proposals={proposals} />
            </Col>
          </Row>
        )}
        <Row>
          <Col lg={12}>
            <ClaimArbitration claim={claim} />
          </Col>
        </Row>
        {details && (
          <Row>
            <Col lg={12}>
              <RepositCard title="Claim Description" tooltip="lorem">
                <P1>{details}</P1>
              </RepositCard>
            </Col>
          </Row>
        )}
        {notes && (
          <Row>
            <Col lg={12}>
              <RepositCard title="Notes" tooltip="lorem">
                <P1>{notes}</P1>
              </RepositCard>
            </Col>
          </Row>
        )}
        {repositMessage && (
          <Row>
            <Col lg={12}>
              <RepositCard title="Reposit Messages" tooltip="lorem">
                <P1>{repositMessage}</P1>
              </RepositCard>
            </Col>
          </Row>
        )}
        {agentResponse && (
          <Row>
            <Col lg={12}>
              <RepositCard title="Agent Response" tooltip="lorem">
                <P1>{agentResponse}</P1>
              </RepositCard>
            </Col>
          </Row>
        )}
        {tenancyInventory ? <InventoryDetails inventory={tenancyInventory} claimId={claim.id} /> : null}
        {documents && documents.length > 0 && (
          <Row>
            <Col lg={12}>
              <ClaimDocuments documents={documents} />
            </Col>
          </Row>
        )}
        {claim && claim.tenantDisputeMessage && (
          <Row>
            <Col lg={12}>
              <RepositCard flush title="Dispute Information">
                <DisputeInformation claim={claim} />
              </RepositCard>
            </Col>
          </Row>
        )}

        {renderStandardConfirmationModal()}
        {currentClaimModal === 'setPaidAt' && (
          <Modal onDismiss={() => dispatch(setCurrentClaimActionModal(''))}>
            <Header3 style={{ marginBottom: 6 }}>Please, select the payment date</Header3>
            <Formik initialValues={{ paidAt: undefined }} onSubmit={handleSetPaid}>
              {(props) => (
                <>
                  <FieldWithLabel label="Paid at" style={{ marginBottom: 24 }}>
                    <DatePicker
                      name="paidAt"
                      value={props.values.paidAt}
                      onBlur={props.handleBlur}
                      dateFormat={DATE_FORMAT}
                      placeholder={'DD/MM/YYYY'}
                      maxDate={new Date()}
                      onChange={(date) => props.setFieldValue('paidAt', date)}
                    />
                  </FieldWithLabel>
                  <ActionBar>
                    <Button onClick={props.handleSubmit} disabled={!props.values.paidAt || loadingSetPaidAt}>
                      Confirm
                    </Button>
                  </ActionBar>
                </>
              )}
            </Formik>
          </Modal>
        )}
        {currentClaimModal === 'createArbitration' && (
          <Modal onDismiss={() => dispatch(setCurrentClaimActionModal(''))}>
            {createArbitrationFlashMessagePayload ? (
              <FlashMessage
                payload={createArbitrationFlashMessagePayload}
                onDismiss={onDismissCreateArbitrationFlashMessage}
                timeRemaining={FLASH_MESSAGE_TIMEOUT}
              />
            ) : null}
            <Header3 style={{ marginBottom: 6 }}>Create arbitration</Header3>
            <Formik
              validationSchema={ARBITRATION_VALIDATION_SCHEMA}
              initialValues={{ arbitratedAmount: undefined }}
              onSubmit={handleCreateArbitration}
            >
              {({ errors, touched, values, isValid, handleChange, handleBlur, handleSubmit }) => (
                <>
                  <FieldWithLabel label="Amount" error={errors.arbitratedAmount} touched={touched.arbitratedAmount}>
                    <Input
                      name="arbitratedAmount"
                      type="number"
                      value={`${values.arbitratedAmount}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon={poundIcon}
                      error={errors.arbitratedAmount}
                      touched={touched.arbitratedAmount}
                    />
                  </FieldWithLabel>
                  <br />
                  <FieldWithLabel label="Summary Document">
                    <UploaderContainer>
                      <BrowseLabel htmlFor="arbitrationDocument">Select a file</BrowseLabel>
                      <UploaderDocumentName>{arbitrationFile && arbitrationFile.name}</UploaderDocumentName>
                    </UploaderContainer>
                    <InputFile id="arbitrationDocument" name="arbitrationDocument" onChange={handleFileChange} />
                  </FieldWithLabel>
                  <ActionBar>
                    <Button onClick={handleSubmit} disabled={loadingCreateArbitration || !isValid || !arbitrationFile}>
                      Confirm
                    </Button>
                  </ActionBar>
                </>
              )}
            </Formik>
          </Modal>
        )}

        {currentClaimModal === 'waiveDisputeFee' ? (
          <WaiveClaimDisputeFeeModal
            isSubmitting={isFeeBeingWaived}
            onDismiss={() => dispatch(setCurrentClaimActionModal(''))}
            onSubmit={waiveClaimDisputeFee}
          />
        ) : null}

        {currentClaimModal === 'reopenClaim' && (
          <ReopenModal
            type={ReopenType.CLAIM}
            onSubmit={(date) =>
              dispatch(reopenClaimApiRequested({ claimId: claim.id, outcomeDeadline: moment(date).format('YYYY-MM-DD') }))
            }
            onDismiss={() => dispatch(setCurrentClaimActionModal(''))}
            isSubmitting={isSubmittingReopen}
          />
        )}

        {/* Leaving here */}
        {/* {currentClaimModal === 'downloadLocalDocuments' && (
          <Modal onDismiss={() => dispatch(setCurrentClaimActionModal(''))}>
            <Header3>Download Documents Locally!</Header3>
            <P2>Are you sure you would like to download all of the documents attached to this Claim?</P2>
            <ActionBar>
              <Button onClick={downloadDocumentsLocal}>Confirm</Button>
            </ActionBar>
          </Modal>
        )} */}

        {currentClaimModal === 'createPaymentPlan' ? (
          // pass in order customers for correct order instead of claim respondents
          <PaymentPlanModal
            onDismiss={() => dispatch(setCurrentClaimActionModal(''))}
            totalBalance={ppOrderAmountRemaining || 0}
            orderCustomers={ppOrderCustomers}
            tenants={tenants}
            onSubmit={createPaymentPlan}
            isSubmitting={isPPSubmitting}
          />
        ) : null}

        {currentClaimModal === 'deletePaymentPlan' ? (
          <DeletePaymentPlanModal
            onDismiss={() => dispatch(setCurrentClaimActionModal(''))}
            onSubmit={deletePaymentPlan}
            isSubmitting={isDeletePPSubmitting}
          />
        ) : null}

        {currentClaimModal === 'downloadDocuments' ? (
          <DownloadClaimDocumentsModal
            onDismiss={() => dispatch(setCurrentClaimActionModal(''))}
            onSubmit={(type) => dispatch(downloadDocumentsApiRequested({ claimId: claim.id, type }))}
            isSubmitting={isDownloadingClaimDocuments}
          />
        ) : null}
      </>
    );
  };

  return (
    <Container style={{ marginBottom: 120 }}>
      {/* TODO: NEED TO ADD LOADING FOR ppOrderAmountRemaining here */}
      {!isFetchClaimLoading && hasClaimFetched && claim && claim.checkout ? (
        renderClaimDetails(claim, isAgentDeciding, isTenantDeciding)
      ) : (
        <Loading />
      )}
    </Container>
  );
};

export default ClaimView;
