import React from 'react';
import numeral from 'numeral';
import { ClaimWithCheckoutRelationsDTO } from '@reposit/api-client';
import styled from 'styled-components';

import RepositCard from '../Reposit/RepositCard';
import { Table, Thead, Tr, Td, Tbody } from '../Table';
import { A, Header4, P2 } from '../Typography';
import { truncateFilename } from '../../utils/common.utils';
import { NumberFormat } from '../../constants/number-formats';
import InlineTable from '../InlineTable';
import moment from 'moment';
import { Container } from 'react-grid-system';

interface ClaimArbitrationProps {
  claim: ClaimWithCheckoutRelationsDTO;
}

const CenteredContent = styled.div`
  padding: 20px 20px 0;
  text-align: center;
`;

const TableSpacer = styled.div`
  height: 20px;
`;

const ClaimArbitration: React.FC<ClaimArbitrationProps> = ({ claim }) => {
  const { arbitration, adjudicationDocumentationGeneratedAt = '', adjudicationOutcomeExpectedAt = '' } = claim;

  const { id = '', arbitratedAmount = '', documents = [] } = arbitration || {};
  if (!arbitration && !adjudicationDocumentationGeneratedAt) {
    return null;
  }

  return (
    <RepositCard flush title="Arbitration" tooltip="lorem">
      <Container>
        {adjudicationDocumentationGeneratedAt && (
          <>
            <Header4 style={{ padding: '0 20px' }}>Details</Header4>
            <InlineTable
              items={[
                {
                  key: 'ADR form created on',
                  value: moment(adjudicationDocumentationGeneratedAt).format('DD/MM/YYYY'),
                },
                {
                  key: 'ADR due date',
                  value: adjudicationOutcomeExpectedAt
                    ? moment(adjudicationOutcomeExpectedAt).format('DD/MM/YYYY')
                    : 'No estimated outcome date',
                },
              ]}
            />
            {arbitration && <TableSpacer />}
          </>
        )}
        {arbitration ? (
          <>
            <Header4 style={{ padding: '0 20px' }}>Documents</Header4>
            <Table>
              <Thead>
                <Tr>
                  <Td>ID</Td>
                  <Td>Amount</Td>
                  <Td>Summary Document</Td>
                  <Td>Uploaded At</Td>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>{id}</Td>
                  <Td>{arbitratedAmount ? `£${numeral(arbitratedAmount / 100).format(NumberFormat.THOUSANDS_PENCE)}` : ''}</Td>
                  <Td>
                    {documents &&
                      documents.map((arbitrationDocument) => (
                        <>
                          <A target="_blank" href={arbitrationDocument.document.url}>
                            {truncateFilename(arbitrationDocument.document.name)}
                          </A>
                          <br />
                        </>
                      ))}
                  </Td>
                  <Td>{moment(arbitration.createdAt).format('DD/MM/YYYY HH:mm:ss')}</Td>
                </Tr>
              </Tbody>
            </Table>
          </>
        ) : (
          <CenteredContent>
            <P2>No Arbitration uploaded yet</P2>
          </CenteredContent>
        )}
      </Container>
    </RepositCard>
  );
};

export default ClaimArbitration;
