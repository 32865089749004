import { OrganizationVerticalDTOTypeEnum } from '@reposit/api-client';
import { AppState } from '../root.reducer';
import { filter, values, find } from 'lodash';

export const getOrganizationVerticals = (state: AppState) => values(state.entities.organizationVertical);

const getOrganizationVerticalIds = (state: AppState): string[] => state.organizationVertical.organizationVerticals;

export const getPrimaryVertical = (state: AppState) => {
  const validIds = getOrganizationVerticalIds(state);
  const primaryVertical = find(
    getOrganizationVerticals(state),
    (entity) => entity.type === OrganizationVerticalDTOTypeEnum.PRIMARY && validIds.includes(entity.id)
  );
  if (!primaryVertical) {
    return null;
  }
  return primaryVertical;
};

export const getSecondaryVerticals = (state: AppState) => {
  const validIds = getOrganizationVerticalIds(state);
  return filter(
    getOrganizationVerticals(state),
    (entity) => entity.type === OrganizationVerticalDTOTypeEnum.SECONDARY && validIds.includes(entity.id)
  );
};
