import React from 'react';
import styled from 'styled-components';
import { ClaimProposalDTO } from '@reposit/api-client';
import { Container } from 'react-grid-system';
import { ClaimItem } from '../../redux/selectors/mediation.selectors';
import RepositCard from '../Reposit/RepositCard';
import { Header4, P2 } from '../Typography';
import MediationClaimItem from './MediationClaimItem';
import { Table, Tbody, Td, Tr } from '../Table';
import TenantActionStatus from './TenantActionStatus';

const Section = styled.div`
  padding: 0 0 5px 0;
`;

const SectionHeader = styled(Header4)`
  padding: 0 20px;
`;

const SectionText = styled(P2)`
  padding: 0 20px;
`;

interface BaseMediationClaimItemsProps {
  claimItems: ClaimItem[];
  proposals: ClaimProposalDTO[];
}

export const MediationClaimItems: React.FC<BaseMediationClaimItemsProps> = ({ claimItems, proposals }) => {
  const settledItems = claimItems.filter((ci) => ci.isSettled);
  const nonSettledItems = claimItems.filter((ci) => !ci.isSettled);

  return (
    <RepositCard flush title={'Claim Items'}>
      <Container>
        <SectionHeader>Details</SectionHeader>
        <Section>
          <Table noBorder>
            <Tbody>
              <Tr>
                <Td colSpan={2}>
                  <TenantActionStatus proposals={proposals} />
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Section>
        <SectionHeader>Non Settled Items</SectionHeader>
        {nonSettledItems.length ? (
          nonSettledItems.map((claimItem) => {
            return <MediationClaimItem key={claimItem.id} claimItem={claimItem} />;
          })
        ) : (
          <SectionText>All items have been settled</SectionText>
        )}
        <SectionHeader>Settled Items</SectionHeader>
        {settledItems.length ? (
          settledItems.map((claimItem) => {
            return <MediationClaimItem key={claimItem.id} claimItem={claimItem} />;
          })
        ) : (
          <SectionText>No settled claim items</SectionText>
        )}
      </Container>
    </RepositCard>
  );
};
