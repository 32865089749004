import {
  AddressDTO,
  CheckoutDTO,
  CreateAddressForRepositDTO,
  CreateTenancyRequestDTO,
  TenancyDTO,
  TenancyOrderDTO,
} from '@reposit/api-client';
import {
  AddRepositNotePayload,
  AddTenantPayload,
  CreateTenantPayload,
  DeleteTenantPayload,
  ExtendRepositPayload,
  RemoveTenantPayload,
  ReopenRepositPayload,
  SetCurrentRepositActionModalPayload,
  TransferRepositRequestedPayload,
  UpdatePropertyPayload,
  UpdateTenancyPayload,
  DeleteRepositNotePayload,
  SetTenantEditFormPayload,
  UpdateTenantDetailsPayload,
  AcknowledgeCaseSentToInsurerPayload,
} from './reposit.types';

export const CREATE_TENANCY_WITH_PROPERTY_STORE_KEY = `REPOSIT/CREATE_TENANCY_WITH_PROPERTY`;
export const CREATE_TENANCY_WITH_PROPERTY_API_REQUESTED = `REPOSIT/CREATE_TENANCY_WITH_PROPERTY_API_REQUESTED`;
export const CREATE_TENANCY_WITH_PROPERTY_API_SUCCESS = `REPOSIT/CREATE_TENANCY_WITH_PROPERTY_API_SUCCESS`;
export const CREATE_TENANCY_WITH_PROPERTY_API_FAILED = `REPOSIT/CREATE_TENANCY_WITH_PROPERTY_API_FAILED`;

export const UPDATE_TENANCY_STORE_KEY = `REPOSIT/UPDATE_TENANCY`;
export const UPDATE_TENANCY_API_REQUESTED = `REPOSIT/UPDATE_TENANCY_API_REQUESTED`;
export const UPDATE_TENANCY_API_SUCCESS = `REPOSIT/UPDATE_TENANCY_API_SUCCESS`;
export const UPDATE_TENANCY_API_FAILED = `REPOSIT/UPDATE_TENANCY_API_FAILED`;

export const FETCH_TENANCY_ORDER_STORE_KEY = `REPOSIT/FETCH_TENANCY_ORDER`;
export const FETCH_TENANCY_ORDER_API_REQUESTED = `REPOSIT/FETCH_TENANCY_ORDER_API_REQUESTED`;
export const FETCH_TENANCY_ORDER_API_SUCCESS = `REPOSIT/FETCH_TENANCY_ORDER_API_SUCCESS`;
export const FETCH_TENANCY_ORDER_API_FAILED = `REPOSIT/FETCH_TENANCY_ORDER_API_FAILED`;

export const CLEAR_TENANCY_ORDER_HAS_FETCHED = `REPOSIT/CLEAR_TENANCY_ORDER_HAS_FETCHED`;

export const CREATE_TENANT_STORE_KEY = `REPOSIT/CREATE_TENANT`;
export const CREATE_TENANT_API_REQUESTED = `REPOSIT/CREATE_TENANT_API_REQUESTED`;
export const CREATE_TENANT_API_SUCCESS = `REPOSIT/CREATE_TENANT_API_SUCCESS`;
export const CREATE_TENANT_API_FAILED = `REPOSIT/CREATE_TENANT_API_FAILED`;

export const DELETE_TENANT_STORE_KEY = `REPOSIT/DELETE_TENANT`;
export const DELETE_TENANT_API_REQUESTED = `REPOSIT/DELETE_TENANT_API_REQUESTED`;
export const DELETE_TENANT_API_SUCCESS = `REPOSIT/DELETE_TENANT_API_SUCCESS`;
export const DELETE_TENANT_API_FAILED = `REPOSIT/DELETE_TENANT_API_FAILED`;

export const UPDATE_TENANT_DETAILS_STORE_KEY = 'REPOSIT/UPDATE_TENANT_DETAILS';
export const UPDATE_TENANT_DETAILS_API_REQUESTED = 'REPOSIT/UPDATE_TENANT_DETAILS_API_REQUESTED';
export const UPDATE_TENANT_DETAILS_API_SUCCESS = 'REPOSIT/UPDATE_TENANT_DETAILS_API_SUCCESS';
export const UPDATE_TENANT_DETAILS_API_FAILED = 'REPOSIT/UPDATE_TENANT_DETAILS_API_FAILED';

export const UPDATE_PROPERTY_STORE_KEY = `REPOSIT/UPDATE_PROPERTY`;
export const UPDATE_PROPERTY_API_REQUESTED = `REPOSIT/UPDATE_PROPERTY_API_REQUESTED`;
export const UPDATE_PROPERTY_API_SUCCESS = `REPOSIT/UPDATE_PROPERTY_API_SUCCESS`;
export const UPDATE_PROPERTY_API_FAILED = `REPOSIT/UPDATE_PROPERTY_API_FAILED`;

export const SET_IS_TENANT_FORM_OPEN = `REPOSIT/SET_IS_TENANT_FORM_OPEN`;
export const SET_IS_PROPERTY_FORM_OPEN = `REPOSIT/SET_IS_PROPERTY_FORM_OPEN`;
export const SET_SELECTED_ADDRESS_ID = `REPOSIT/SET_SELECTED_ADDRESS_ID`;
export const SET_CURRENT_ADDRESS_FORM = `REPOSIT/SET_CURRENT_ADDRESS_FORM`;

export const CREATE_ADDRESS_STORE_KEY = `REPOSIT/CREATE_ADDRESS`;
export const CREATE_ADDRESS_API_REQUESTED = `REPOSIT/CREATE_ADDRESS_API_REQUESTED`;
export const CREATE_ADDRESS_API_SUCCESS = `REPOSIT/CREATE_ADDRESS_API_SUCCESS`;
export const CREATE_ADDRESS_API_FAILED = `REPOSIT/CREATE_ADDRESS_API_FAILED`;

export const DISCARD_REPOSIT_STORE_KEY = `REPOSIT/DISCARD_REPOSIT`;
export const DISCARD_REPOSIT_API_REQUESTED = `REPOSIT/DISCARD_REPOSIT_API_REQUESTED`;
export const DISCARD_REPOSIT_API_SUCCESS = `REPOSIT/DISCARD_REPOSIT_API_SUCCESS`;
export const DISCARD_REPOSIT_API_FAILED = `REPOSIT/DISCARD_REPOSIT_API_FAILED`;

export const PUBLISH_REPOSIT_STORE_KEY = `REPOSIT/PUBLISH_REPOSIT`;
export const PUBLISH_REPOSIT_API_REQUESTED = `REPOSIT/PUBLISH_REPOSIT_API_REQUESTED`;
export const PUBLISH_REPOSIT_API_SUCCESS = `REPOSIT/PUBLISH_REPOSIT_API_SUCCESS`;
export const PUBLISH_REPOSIT_API_FAILED = `REPOSIT/PUBLISH_REPOSIT_API_FAILED`;

export const CHECKOUT_REPOSIT_STORE_KEY = `REPOSIT/CHECKOUT_REPOSIT`;
export const CHECKOUT_REPOSIT_API_REQUESTED = `REPOSIT/CHECKOUT_REPOSIT_API_REQUESTED`;
export const CHECKOUT_REPOSIT_API_SUCCESS = `REPOSIT/CHECKOUT_REPOSIT_API_SUCCESS`;
export const CHECKOUT_REPOSIT_API_FAILED = `REPOSIT/CHECKOUT_REPOSIT_API_FAILED`;

export const EXTEND_REPOSIT_STORE_KEY = `REPOSIT/EXTEND_REPOSIT`;
export const EXTEND_REPOSIT_API_REQUESTED = `REPOSIT/EXTEND_REPOSIT_API_REQUESTED`;
export const EXTEND_REPOSIT_API_SUCCESS = `REPOSIT/EXTEND_REPOSIT_API_SUCCESS`;
export const EXTEND_REPOSIT_API_FAILED = `REPOSIT/EXTEND_REPOSIT_API_FAILED`;

export const ADD_TENANT_ON_REPOSIT_STORE_KEY = `REPOSIT/ADD_TENANT`;
export const ADD_TENANT_ON_REPOSIT_API_REQUESTED = `REPOSIT/ADD_TENANT_API_REQUESTED`;
export const ADD_TENANT_ON_REPOSIT_API_SUCCESS = `REPOSIT/ADD_TENANT_API_SUCCESS`;
export const ADD_TENANT_ON_REPOSIT_API_FAILED = `REPOSIT/ADD_TENANT_API_FAILED`;

export const REMOVE_TENANT_ON_REPOSIT_STORE_KEY = `REPOSIT/REMOVE_TENANT`;
export const REMOVE_TENANT_ON_REPOSIT_API_REQUESTED = `REPOSIT/REMOVE_TENANT_API_REQUESTED`;
export const REMOVE_TENANT_ON_REPOSIT_API_SUCCESS = `REPOSIT/REMOVE_TENANT_API_SUCCESS`;
export const REMOVE_TENANT_ON_REPOSIT_API_FAILED = `REPOSIT/REMOVE_TENANT_API_FAILED`;

export const SET_IS_UPDATE_PROPERTY_MODAL_OPEN = `REPOSIT/SET_IS_UPDATE_PROPERTY_MODAL_OPEN`;

export const SET_CURRENT_REPOSIT_ACTION_MODAL = `REPOSIT/SET_CURRENT_REPOSIT_ACTION_MODAL`;

export const RESEND_TENANT_INVITE_STORE_KEY = `REPOSIT/RESEND_TENANT_INVITE`;
export const RESEND_TENANT_INVITE_API_REQUESTED = `REPOSIT/RESEND_TENANT_INVITE_API_REQUESTED`;
export const RESEND_TENANT_INVITE_API_SUCCESS = `REPOSIT/RESEND_TENANT_INVITE_API_SUCCESS`;
export const RESEND_TENANT_INVITE_API_FAILED = `REPOSIT/RESEND_TENANT_INVITE_API_FAILED`;

export const TRANSFER_REPOSIT_STORE_KEY = 'REPOSIT/TRANSFER_REPOSIT';
export const TRANSFER_REPOSIT_API_REQUESTED = 'REPOSIT/TRANSFER_REPOSIT_API_REQUESTED';
export const TRANSFER_REPOSIT_API_SUCCESS = 'REPOSIT/TRANSFER_REPOSIT_API_SUCCESS';
export const TRANSFER_REPOSIT_API_FAILED = 'REPOSIT/TRANSFER_REPOSIT_API_FAILED';

export const SET_IS_TRANSFER_MODAL_OPEN = `REPOSIT/SET_IS_TRANSFER_MODAL_OPEN`;

export const EXTEND_OUTCOME_DEADLINE_STORE_KEY = 'REPOSIT/EXTEND_OUTCOME_DEADLINE';
export const EXTEND_OUTCOME_DEADLINE_API_REQUESTED = 'REPOSIT/EXTEND_OUTCOME_DEADLINE_API_REQUESTED';
export const EXTEND_OUTCOME_DEADLINE_API_SUCCESS = 'REPOSIT/EXTEND_OUTCOME_DEADLINE_API_SUCCESS';
export const EXTEND_OUTCOME_DEADLINE_API_FAILED = 'REPOSIT/EXTEND_OUTCOME_DEADLINE_API_FAILED';

export const DISABLE_TOP_UPS_STORE_KEY = 'REPOSIT/DISABLE_TOP_UPS';
export const DISABLE_TOP_UPS_API_REQUESTED = 'REPOSIT/DISABLE_TOP_UPS_API_REQUESTED';
export const DISABLE_TOP_UPS_API_SUCCESS = 'REPOSIT/DISABLE_TOP_UPS_API_SUCCESS';
export const DISABLE_TOP_UPS_API_FAILED = 'REPOSIT/DISABLE_TOP_UPS_API_FAILED';

export const REOPEN_REPOSIT_STORE_KEY = 'REPOSIT/REOPEN_REPOSIT';
export const REOPEN_REPOSIT_API_REQUESTED = 'REPOSIT/REOPEN_REPOSIT_API_REQUESTED';
export const REOPEN_REPOSIT_API_SUCCESS = 'REPOSIT/REOPEN_REPOSIT_API_SUCCESS';
export const REOPEN_REPOSIT_API_FAILED = 'REPOSIT/REOPEN_REPOSIT_API_FAILED';

export const ADD_REPOSIT_NOTE_STORE_KEY = 'REPOSIT/ADD_NOTE';
export const ADD_REPOSIT_NOTE_API_REQUESTED = 'REPOSIT/ADD_NOTE_API_REQUESTED';
export const ADD_REPOSIT_NOTE_API_SUCCESS = 'REPOSIT/ADD_NOTE_API_SUCCESS';
export const ADD_REPOSIT_NOTE_API_FAILED = 'REPOSIT/ADD_NOTE_API_FAILED';

export const DELETE_REPOSIT_NOTE_STORE_KEY = 'REPOSIT/DELETE_NOTE';
export const DELETE_REPOSIT_NOTE_API_REQUESTED = 'REPOSIT/DELETE_NOTE_API_REQUESTED';
export const DELETE_REPOSIT_NOTE_API_SUCCESS = 'REPOSIT/DELETE_NOTE_API_SUCCESS';
export const DELETE_REPOSIT_NOTE_API_FAILED = 'REPOSIT/DELETE_NOTE_API_FAILED';

export const ACKNOWLEDGE_CASE_SENT_TO_INSURER_STORE_KEY = 'REPOSIT/ACKNOWLEDGE_CASE_SENT_TO_INSURER';
export const ACKNOWLEDGE_CASE_SENT_TO_INSURER_API_REQUESTED = 'REPOSIT/ACKNOWLEDGE_CASE_SENT_TO_INSURER_API_REQUESTED';
export const ACKNOWLEDGE_CASE_SENT_TO_INSURER_API_SUCCESS = 'REPOSIT/ACKNOWLEDGE_CASE_SENT_TO_INSURER_API_SUCCESS';
export const ACKNOWLEDGE_CASE_SENT_TO_INSURER_API_FAILED = 'REPOSIT/ACKNOWLEDGE_CASE_SENT_TO_INSURER_API_FAILED';

export const SET_TENANT_EDIT_FORM_OPEN = 'REPOSIT/SET_TENANT_EDIT_FORM_OPEN';

export function setTenantEditFormOpenStatus(payload: SetTenantEditFormPayload) {
  return {
    type: SET_TENANT_EDIT_FORM_OPEN,
    payload,
  } as const;
}

/* -------------------------------------------- CREATE TENANCY -------------------------------------------- */
export function createTenancyWithPropertyRequested(payload: CreateTenancyRequestDTO) {
  return {
    type: CREATE_TENANCY_WITH_PROPERTY_API_REQUESTED,
    payload,
  } as const;
}
export function createTenancyWithPropertySuccess() {
  return {
    type: CREATE_TENANCY_WITH_PROPERTY_API_SUCCESS,
  } as const;
}
export function createTenancyWithPropertyFailed(error: string) {
  return {
    type: CREATE_TENANCY_WITH_PROPERTY_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- UPDATE TENANCY -------------------------------------------- */
export function updateTenancyRequested(payload: UpdateTenancyPayload) {
  return {
    type: UPDATE_TENANCY_API_REQUESTED,
    payload,
  } as const;
}
export function updateTenancySuccess() {
  return {
    type: UPDATE_TENANCY_API_SUCCESS,
  } as const;
}
export function updateTenancyFailed(error: string) {
  return {
    type: UPDATE_TENANCY_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- FETCH TENANCY ORDER -------------------------------------------- */
export function fetchTenancyOrderRequested(tenancyOrderId: string) {
  return {
    type: FETCH_TENANCY_ORDER_API_REQUESTED,
    payload: tenancyOrderId,
  } as const;
}
export function fetchTenancyOrderSuccess() {
  return {
    type: FETCH_TENANCY_ORDER_API_SUCCESS,
  } as const;
}
export function fetchTenancyOrderFailed(error: string) {
  return {
    type: FETCH_TENANCY_ORDER_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- CLEAR TENANCY ORDER HAS FETCHED -------------------------------------------- */
export function clearTenancyOrderHasFetched() {
  return {
    type: CLEAR_TENANCY_ORDER_HAS_FETCHED,
  } as const;
}

/* -------------------------------------------- CREATE TENANT -------------------------------------------- */
export function createTenantRequested(payload: CreateTenantPayload) {
  return {
    type: CREATE_TENANT_API_REQUESTED,
    payload,
  } as const;
}
export function createTenantSuccess() {
  return {
    type: CREATE_TENANT_API_SUCCESS,
  } as const;
}
export function createTenantFailed(error: string) {
  return {
    type: CREATE_TENANT_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- DELETE TENANT -------------------------------------------- */
export function deleteTenantRequested(payload: DeleteTenantPayload) {
  return {
    type: DELETE_TENANT_API_REQUESTED,
    payload,
  } as const;
}
export function deleteTenantSuccess(payload: DeleteTenantPayload) {
  return {
    type: DELETE_TENANT_API_SUCCESS,
    payload,
  } as const;
}
export function deleteTenantFailed(error: string) {
  return {
    type: DELETE_TENANT_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- UPDATE TENANT -------------------------------------------- */
export function updateTenantDetailsRequested(payload: UpdateTenantDetailsPayload) {
  return {
    type: UPDATE_TENANT_DETAILS_API_REQUESTED,
    payload,
  } as const;
}
export function updateTenantDetailsSuccess() {
  return {
    type: UPDATE_TENANT_DETAILS_API_SUCCESS,
  } as const;
}
export function updateTenantDetailsFailed(error: string) {
  return {
    type: UPDATE_TENANT_DETAILS_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- UPDATE PROPERTY -------------------------------------------- */
export function updatePropertyRequested(payload: UpdatePropertyPayload) {
  return {
    type: UPDATE_PROPERTY_API_REQUESTED,
    payload,
  } as const;
}
export function updatePropertySuccess(payload: TenancyDTO) {
  return {
    type: UPDATE_PROPERTY_API_SUCCESS,
    payload,
  } as const;
}
export function updatePropertyFailed(error: string) {
  return {
    type: UPDATE_PROPERTY_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- TENANT FORM OPEN -------------------------------------------- */
export function setIsTenantFormOpen(payload: boolean) {
  return {
    type: SET_IS_TENANT_FORM_OPEN,
    payload,
  } as const;
}

/* -------------------------------------------- Property FORM OPEN -------------------------------------------- */
export function setIsPropertyFormOpen(payload: boolean) {
  return {
    type: SET_IS_PROPERTY_FORM_OPEN,
    payload,
  } as const;
}

/* -------------------------------------------- set selected address id -------------------------------------------- */
export function setSelectedAddressId(payload?: string) {
  return {
    type: SET_SELECTED_ADDRESS_ID,
    payload,
  } as const;
}

/* -------------------------------------------- CREATE ADDRESS -------------------------------------------- */
export function createAddressForRepositRequested(payload: CreateAddressForRepositDTO) {
  return {
    type: CREATE_ADDRESS_API_REQUESTED,
    payload,
  } as const;
}
export function createAddressForRepositSuccess(payload: AddressDTO) {
  return {
    type: CREATE_ADDRESS_API_SUCCESS,
    payload,
  } as const;
}
export function createAddressForRepositFailed(error: string) {
  return {
    type: CREATE_ADDRESS_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- set current address form -------------------------------------------- */

export enum AddressForm {
  ADDRESS_LIST = 'ADDRESS_LIST',
  POSTCODE = 'POSTCODE',
  FULL_ADDRESS = 'FULL_ADDRESS',
}
export function setRepositCurrentAddressForm(payload: AddressForm) {
  return {
    type: SET_CURRENT_ADDRESS_FORM,
    payload,
  } as const;
}

/* -------------------------------------------- set is property update modal open -------------------------------------------- */

export function setIsUpdatePropertyModalOpen(payload: boolean) {
  return {
    type: SET_IS_UPDATE_PROPERTY_MODAL_OPEN,
    payload,
  } as const;
}

/* -------------------------------------------- DISCARD REPOSIT -------------------------------------------- */
export function discardRepositRequested(tenancyOrderId: string, reason?: string) {
  return {
    type: DISCARD_REPOSIT_API_REQUESTED,
    payload: {
      tenancyOrderId,
      reason,
    },
  } as const;
}
export function discardRepositSuccess(payload: TenancyOrderDTO) {
  return {
    type: DISCARD_REPOSIT_API_SUCCESS,
    payload,
  } as const;
}
export function discardRepositFailed(error: string) {
  return {
    type: DISCARD_REPOSIT_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- PUBLISH REPOSIT -------------------------------------------- */
export function publishRepositRequested(payload: any) {
  return {
    type: PUBLISH_REPOSIT_API_REQUESTED,
    payload,
  } as const;
}

export function publishRepositSuccess(payload: TenancyDTO) {
  return {
    type: PUBLISH_REPOSIT_API_SUCCESS,
    payload,
  } as const;
}

export function publishRepositFailed(error: string) {
  return {
    type: PUBLISH_REPOSIT_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- CHECKOUT REPOSIT -------------------------------------------- */
export function checkoutRepositRequested(payload: TenancyOrderDTO) {
  return {
    type: CHECKOUT_REPOSIT_API_REQUESTED,
    payload,
  } as const;
}

export function checkoutRepositSuccess(payload: CheckoutDTO) {
  return {
    type: CHECKOUT_REPOSIT_API_SUCCESS,
    payload,
  } as const;
}

export function checkoutRepositFailed(error: string) {
  return {
    type: CHECKOUT_REPOSIT_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- EXTEND REPOSIT -------------------------------------------- */
export function extendRepositRequested(payload: ExtendRepositPayload) {
  return {
    type: EXTEND_REPOSIT_API_REQUESTED,
    payload,
  } as const;
}

export function extendRepositSuccess() {
  return {
    type: EXTEND_REPOSIT_API_SUCCESS,
  } as const;
}

export function extendRepositFailed(error: string) {
  return {
    type: EXTEND_REPOSIT_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- ADD TENANT ON REPOSIT -------------------------------------------- */
export function addTenantOnRepositRequested(payload: AddTenantPayload) {
  return {
    type: ADD_TENANT_ON_REPOSIT_API_REQUESTED,
    payload,
  } as const;
}

export function addTenantOnRepositSuccess() {
  return {
    type: ADD_TENANT_ON_REPOSIT_API_SUCCESS,
  } as const;
}

export function addTenantOnRepositFailed(error: string) {
  return {
    type: ADD_TENANT_ON_REPOSIT_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- REMOVE TENANT ON REPOSIT -------------------------------------------- */
export function removeTenantOnRepositRequested(payload: RemoveTenantPayload) {
  return {
    type: REMOVE_TENANT_ON_REPOSIT_API_REQUESTED,
    payload,
  } as const;
}

export function removeTenantOnRepositSuccess(payload: RemoveTenantPayload) {
  return {
    type: REMOVE_TENANT_ON_REPOSIT_API_SUCCESS,
    payload,
  } as const;
}

export function removeTenantOnRepositFailed(error: string) {
  return {
    type: REMOVE_TENANT_ON_REPOSIT_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- SET CURRENT REPOSIT ACTION MODAL -------------------------------------------- */
export function setCurrentRepositActionModal(payload: SetCurrentRepositActionModalPayload) {
  return {
    type: SET_CURRENT_REPOSIT_ACTION_MODAL,
    payload,
  } as const;
}

/* -------------------------------------------------- RESEND TENANT INVITE  --------------------------------------------------- */

export function resendTenantInviteRequested(tenancyId: string, tenancyInviteId: string) {
  return {
    type: RESEND_TENANT_INVITE_API_REQUESTED,
    payload: {
      tenancyId,
      tenancyInviteId,
    },
  } as const;
}

export function resendTenantInviteSuccess() {
  return {
    type: RESEND_TENANT_INVITE_API_SUCCESS,
  } as const;
}

export function resendTenantInviteFailed(error: string) {
  return {
    type: RESEND_TENANT_INVITE_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- TRANSFER REPOSIT -------------------------------------------- */
export function transferRepositRequested(payload: TransferRepositRequestedPayload) {
  return {
    type: TRANSFER_REPOSIT_API_REQUESTED,
    payload,
  } as const;
}
export function transferRepositSuccess() {
  return {
    type: TRANSFER_REPOSIT_API_SUCCESS,
  } as const;
}
export function transferRepositFailed(error: string) {
  return {
    type: TRANSFER_REPOSIT_API_FAILED,
    payload: error,
  } as const;
}

export function setIsTransferModalOpen(payload: boolean) {
  return {
    type: SET_IS_TRANSFER_MODAL_OPEN,
    payload,
  } as const;
}

/* -------------------------------------------- EXTEND OUTCOME DEADLINE -------------------------------------------- */
export function extendOutcomeDeadlineRequested(id: string) {
  return {
    type: EXTEND_OUTCOME_DEADLINE_API_REQUESTED,
    payload: id,
  } as const;
}
export function extendOutcomeDeadlineSuccess(payload: TenancyDTO) {
  return {
    type: EXTEND_OUTCOME_DEADLINE_API_SUCCESS,
    payload,
  } as const;
}
export function extendOutcomeDeadlineFailed(error: string) {
  return {
    type: EXTEND_OUTCOME_DEADLINE_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- DISABLE TOP UPS -------------------------------------------- */
export function disableTopUpsRequested(id: string) {
  return {
    type: DISABLE_TOP_UPS_API_REQUESTED,
    payload: id,
  } as const;
}
export function disableTopUpsSuccess(payload: TenancyDTO) {
  return {
    type: DISABLE_TOP_UPS_API_SUCCESS,
    payload,
  } as const;
}
export function disableTopUpsFailed(error: string) {
  return {
    type: DISABLE_TOP_UPS_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- REOPEN REPOSIT -------------------------------------------- */
export function reopenRepositRequested(payload: ReopenRepositPayload) {
  return {
    type: REOPEN_REPOSIT_API_REQUESTED,
    payload,
  } as const;
}
export function reopenRepositSuccess(tenancyId: string) {
  return {
    type: REOPEN_REPOSIT_API_SUCCESS,
    payload: {
      tenancyId,
    },
  } as const;
}
export function reopenRepositFailed(error: string) {
  return {
    type: REOPEN_REPOSIT_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- ADD NOTE -------------------------------------------- */

export function addRepositNoteRequested(payload: AddRepositNotePayload) {
  return {
    type: ADD_REPOSIT_NOTE_API_REQUESTED,
    payload,
  } as const;
}

export function addRepositNoteSuccess() {
  return {
    type: ADD_REPOSIT_NOTE_API_SUCCESS,
  } as const;
}

export function addRepositNoteFailed(error: string) {
  return {
    type: ADD_REPOSIT_NOTE_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- DELETE NOTE -------------------------------------------- */

export function deleteRepositNoteRequested(payload: DeleteRepositNotePayload) {
  return {
    type: DELETE_REPOSIT_NOTE_API_REQUESTED,
    payload,
  } as const;
}

export function deleteRepositNoteSuccess(payload: DeleteRepositNotePayload) {
  return {
    type: DELETE_REPOSIT_NOTE_API_SUCCESS,
    payload,
  } as const;
}

export function deleteRepositNoteFailed(error: string) {
  return {
    type: DELETE_REPOSIT_NOTE_API_FAILED,
    payload: error,
  } as const;
}

/* -------------------------------------------- ACKNOWLEDGE CASE SENT TO INSURER -------------------------------------------- */

export function acknowledgeCaseSentToInsurerRequested(payload: AcknowledgeCaseSentToInsurerPayload) {
  return {
    type: ACKNOWLEDGE_CASE_SENT_TO_INSURER_API_REQUESTED,
    payload,
  } as const;
}

export function acknowledgeCaseSentToInsurerSuccess() {
  return {
    type: ACKNOWLEDGE_CASE_SENT_TO_INSURER_API_SUCCESS,
  } as const;
}

export function acknowledgeCaseSentToInsurerFailed(error: string) {
  return {
    type: ACKNOWLEDGE_CASE_SENT_TO_INSURER_API_FAILED,
    payload: error,
  } as const;
}

export type RepositActionTypes = ReturnType<
  | typeof createTenancyWithPropertyRequested
  | typeof createTenancyWithPropertySuccess
  | typeof createTenancyWithPropertyFailed
  | typeof updateTenancyRequested
  | typeof updateTenancySuccess
  | typeof updateTenancyFailed
  | typeof fetchTenancyOrderRequested
  | typeof fetchTenancyOrderSuccess
  | typeof fetchTenancyOrderFailed
  | typeof clearTenancyOrderHasFetched
  | typeof createTenantRequested
  | typeof createTenantSuccess
  | typeof createTenantFailed
  | typeof setIsTenantFormOpen
  | typeof setIsPropertyFormOpen
  | typeof deleteTenantRequested
  | typeof deleteTenantSuccess
  | typeof deleteTenantFailed
  | typeof updatePropertyRequested
  | typeof updatePropertySuccess
  | typeof updatePropertyFailed
  | typeof setSelectedAddressId
  | typeof createAddressForRepositRequested
  | typeof createAddressForRepositSuccess
  | typeof createAddressForRepositFailed
  | typeof setRepositCurrentAddressForm
  | typeof setIsUpdatePropertyModalOpen
  | typeof discardRepositRequested
  | typeof discardRepositSuccess
  | typeof discardRepositFailed
  | typeof setCurrentRepositActionModal
  | typeof publishRepositRequested
  | typeof publishRepositSuccess
  | typeof publishRepositFailed
  | typeof checkoutRepositRequested
  | typeof checkoutRepositSuccess
  | typeof checkoutRepositFailed
  | typeof extendRepositRequested
  | typeof extendRepositSuccess
  | typeof extendRepositFailed
  | typeof resendTenantInviteRequested
  | typeof resendTenantInviteSuccess
  | typeof resendTenantInviteFailed
  | typeof transferRepositRequested
  | typeof transferRepositSuccess
  | typeof transferRepositFailed
  | typeof setIsTransferModalOpen
  | typeof addTenantOnRepositRequested
  | typeof addTenantOnRepositSuccess
  | typeof addTenantOnRepositFailed
  | typeof extendOutcomeDeadlineRequested
  | typeof extendOutcomeDeadlineSuccess
  | typeof extendOutcomeDeadlineFailed
  | typeof disableTopUpsRequested
  | typeof disableTopUpsSuccess
  | typeof disableTopUpsFailed
  | typeof removeTenantOnRepositRequested
  | typeof removeTenantOnRepositSuccess
  | typeof removeTenantOnRepositFailed
  | typeof reopenRepositRequested
  | typeof reopenRepositSuccess
  | typeof reopenRepositFailed
  | typeof addRepositNoteRequested
  | typeof addRepositNoteSuccess
  | typeof addRepositNoteFailed
  | typeof deleteRepositNoteRequested
  | typeof deleteRepositNoteSuccess
  | typeof deleteRepositNoteFailed
  | typeof setTenantEditFormOpenStatus
  | typeof updateTenantDetailsRequested
  | typeof updateTenantDetailsSuccess
  | typeof updateTenantDetailsFailed
  | typeof acknowledgeCaseSentToInsurerRequested
  | typeof acknowledgeCaseSentToInsurerSuccess
  | typeof acknowledgeCaseSentToInsurerFailed
>;
