import {
  CreateOrganizationDTOPrimaryVerticalEnum,
  CreateOrganizationDTOSecondaryVerticalsEnum,
  CreateOrganizationDTOTypeEnum,
  InternalTeamMemberDTOTeamEnum,
} from '@reposit/api-client';
import { Formik, FormikProps, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import { getBreakpoint } from '../../base/style';
import Button from '../../components/Button';
import FlashMessage from '../../components/FlashMessage';
import { Input, RadioGroup } from '../../components/FormFields';
import FieldWithLabel from '../../components/FormFields/FieldWithLabel';
import Select from '../../components/FormFields/Select';
import { FullPageLoader } from '../../components/Loading';
import RepositCard from '../../components/Reposit/RepositCard';
import Sort from '../../components/Sort';
import { Header2, P1, P2 } from '../../components/Typography';
import { useIntegrators } from '../../hooks/use-integrators';
import { Option } from '../../interfaces/option.interface';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import { createOrganizationRequested, CREATE_ORGANIZATION_STORE_KEY } from '../../redux/organization/organization.actions';
import { CreateOrganizationPayload } from '../../redux/organization/organization.types';
import { formatIntegratorOptions, formatInternalTeamMembersOptions } from '../../utils/common.utils';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../FlashMessage';
import { useInternalTeamMembers } from '../../hooks/use-internal-team-members';
import Checkbox from '../../components/FormFields/Checkbox';

interface AddNewOrganisationProps {}

const Field = styled.div`
  min-height: 86px;
  @media screen and (min-width: ${getBreakpoint('lg')}) {
    max-width: 94%;
  }
`;

const ActionInfo = styled(P2)`
  margin: 0 10px;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 0 0 140px;
`;

enum IS_INTEGRATOR {
  YES = 'YES',
  NO = 'NO',
}

const verticalOptions = [
  { name: CreateOrganizationDTOPrimaryVerticalEnum.INDEPENDENTSINGLE, label: 'Independent Single' },
  { name: CreateOrganizationDTOPrimaryVerticalEnum.BTR, label: 'Build to Rent' },
  { name: CreateOrganizationDTOPrimaryVerticalEnum.INDEPENDENTMULTI, label: 'Independent Multi' },
  { name: CreateOrganizationDTOPrimaryVerticalEnum.FRANCHISE, label: 'Franchise' },
  { name: CreateOrganizationDTOPrimaryVerticalEnum.CORPORATE, label: 'Corporate' },
  { name: CreateOrganizationDTOPrimaryVerticalEnum.PRIVATELANDLORD, label: 'Private Landlord' },
  { name: CreateOrganizationDTOPrimaryVerticalEnum.STUDENT, label: 'Student' },
  { name: CreateOrganizationDTOPrimaryVerticalEnum.PBSA, label: 'PBSA' },
  { name: CreateOrganizationDTOPrimaryVerticalEnum.HMO, label: 'HMO' },
] as const;

interface AddOrganizationForm {
  organizationName: string;
  hubspotId: string;
  type: CreateOrganizationDTOTypeEnum;
  email: string;
  isIntegrator: IS_INTEGRATOR;
  integratorId?: string;
  salesOwnerId?: string;
  primaryVertical: CreateOrganizationDTOPrimaryVerticalEnum;
  secondaryVerticals: CreateOrganizationDTOSecondaryVerticalsEnum[];
}

const VALIDATION_SCHEMA = yup.object().shape({
  organizationName: yup.string().required('Required'),
  type: yup.string().required('Required'),
  email: yup.string().email().required('Required'),
  isIntegrator: yup.string().required('Required'),
  integratorId: yup.string().when('isIntegrator', {
    is: (isIntegrator: IS_INTEGRATOR) => isIntegrator === IS_INTEGRATOR.YES,
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
  hubspotId: yup.string(),
  salesOwnerId: yup.string(),
  primaryVertical: yup
    .string()
    .oneOf(verticalOptions.map((v) => v.name))
    .required('Required'),
  secondaryVerticals: yup.array().of(yup.string().oneOf(verticalOptions.map((v) => v.name))),
});

const AutoSetIntegratorId = (props: { integratorOptions: Option[] }) => {
  const { values, setFieldValue } = useFormikContext<AddOrganizationForm>();
  useEffect(() => {
    // sets initial integrator
    // OR
    // sets other fields to initial
    if (values.isIntegrator === IS_INTEGRATOR.YES) {
      // first value
      setFieldValue('integratorId', props.integratorOptions[0].value);
    } else {
      setFieldValue('integratorId', undefined);
      setFieldValue('hubspotId', undefined);
      setFieldValue('salesOwnerId', undefined);
    }
  }, [values.isIntegrator, setFieldValue, props.integratorOptions]);
  return null;
};

const AddNewOrganisation: React.FC<AddNewOrganisationProps> = (props) => {
  const dispatch = useDispatch();
  const [flashMessage, dismissFlashMessage] = useFlashMessage([CREATE_ORGANIZATION_STORE_KEY]);
  const loadingSelector = createLoadingSelector([CREATE_ORGANIZATION_STORE_KEY]);
  const isCreateLoading = useSelector(loadingSelector);
  const { isLoading: areIntegratorsLoading, integrators } = useIntegrators();
  const { isLoading: areInternalTeamMembersLoading, internalTeamMembers } = useInternalTeamMembers(
    InternalTeamMemberDTOTeamEnum.SALES
  );
  const integratorOptions = formatIntegratorOptions(integrators);
  const internalTeamMemberSalesOptions = formatInternalTeamMembersOptions(internalTeamMembers);
  const isLoading = isCreateLoading || areIntegratorsLoading || areInternalTeamMembersLoading;
  const onSubmit = (values: AddOrganizationForm) => {
    const { organizationName, type, email, integratorId, hubspotId, salesOwnerId, primaryVertical, secondaryVerticals } = values;

    const integratorData =
      values.isIntegrator === IS_INTEGRATOR.YES
        ? {
            integratorId,
          }
        : {};

    const data: CreateOrganizationPayload = {
      organizationName,
      type,
      email,
      hubspotId,
      salesOwnerId: salesOwnerId === 'none' ? undefined : salesOwnerId,
      primaryVertical,
      secondaryVerticals,
      ...integratorData,
    };

    dispatch(createOrganizationRequested(data));
  };

  if (isLoading || (!integrators.length && integrators.length !== 0)) return <FullPageLoader />;

  return (
    <Formik
      initialValues={{
        organizationName: '',
        type: CreateOrganizationDTOTypeEnum.AGENCY,
        email: '',
        isIntegrator: IS_INTEGRATOR.NO,
        hubspotId: '',
        salesOwnerId: undefined,
        primaryVertical: verticalOptions[0].name,
        secondaryVerticals: [],
      }}
      onSubmit={onSubmit}
      validationSchema={VALIDATION_SCHEMA}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        errors,
        touched,
        isValid,
        setValues,
        setFieldValue,
      }: FormikProps<AddOrganizationForm>) => {
        const orgNameLabel = values.type === CreateOrganizationDTOTypeEnum.PRIVATE ? 'Landlord Full Name *' : 'Name *';
        return (
          <form onSubmit={handleSubmit}>
            <AutoSetIntegratorId integratorOptions={integratorOptions} />
            <Container>
              <Row>
                <Col lg={12}>
                  <div style={{ marginBottom: 40 }}>
                    {flashMessage ? (
                      <FlashMessage
                        payload={flashMessage}
                        onDismiss={dismissFlashMessage}
                        timeRemaining={FLASH_MESSAGE_TIMEOUT}
                      />
                    ) : undefined}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Header2>Add New Organisation</Header2>
                  <P1>
                    Adding a new organisation will create an organisation along with an invite for the associated user.{' '}
                    <span>
                      Please <Link to={'/organisations'}>search</Link> for the organisation first to avoid creating duplicates.
                    </span>
                  </P1>
                  <P1>
                    If an integrator is selected the invite will be automatically accepted and the user onboarded. The integrator
                    will be provided with API credentials for this user, and the user will be notified that the integration has
                    been enabled. The user will be asked to set their password and agree to our T&Cs on initial login.
                  </P1>
                </Col>
              </Row>
              {integrators.length > 0 ? (
                <Row>
                  <Col lg={12}>
                    <RepositCard title="Integrator">
                      <Container fluid>
                        <Row>
                          <Col lg={12} style={{ padding: 0 }}>
                            <FieldWithLabel
                              label="Are you onboarding for an integrator?"
                              error={errors.isIntegrator}
                              touched={touched.isIntegrator}
                            >
                              <RadioGroup
                                name="isIntegrator"
                                // has to be string here annoyingly
                                options={[
                                  { value: IS_INTEGRATOR.YES, label: 'Yes' },
                                  { value: IS_INTEGRATOR.NO, label: 'No' },
                                ]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.isIntegrator}
                                touched={touched.isIntegrator}
                                selected={values.isIntegrator}
                              />
                            </FieldWithLabel>
                          </Col>
                          {values.isIntegrator === IS_INTEGRATOR.YES ? (
                            <Col lg={12} style={{ padding: 0, marginBottom: 10 }}>
                              <FieldWithLabel label="Integrator">
                                <Sort
                                  label="Integrator"
                                  onSelect={(val) => setFieldValue('integratorId', val)}
                                  options={integratorOptions}
                                  selected={values.integratorId || ''}
                                  hideLabel
                                />
                              </FieldWithLabel>
                            </Col>
                          ) : null}
                        </Row>
                      </Container>
                    </RepositCard>
                  </Col>
                </Row>
              ) : null}
              <Row>
                <Col lg={12}>
                  <RepositCard title="Organisation Details">
                    <Container fluid>
                      <Row>
                        <Col lg={12} style={{ padding: 0 }}>
                          <Field>
                            <FieldWithLabel
                              label={orgNameLabel}
                              touched={touched.organizationName}
                              error={errors.organizationName}
                            >
                              <Input
                                name="organizationName"
                                value={values.organizationName}
                                error={errors.organizationName}
                                touched={touched.organizationName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </FieldWithLabel>
                          </Field>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} style={{ padding: 0 }}>
                          <Field>
                            <FieldWithLabel label="Hubspot Company Id" touched={touched.hubspotId} error={errors.hubspotId}>
                              <Input
                                name="hubspotId"
                                value={values.hubspotId}
                                error={errors.hubspotId}
                                touched={touched.hubspotId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </FieldWithLabel>
                          </Field>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} style={{ padding: 0 }}>
                          <Field>
                            <FieldWithLabel label="Type *" touched={touched.type} error={errors.type}>
                              <Select onChange={(type: any) => setValues({ ...values, type })} value={values.type}>
                                <option value={undefined}>Please Select</option>
                                <option value={CreateOrganizationDTOTypeEnum.AGENCY}>Agency</option>
                                <option value={CreateOrganizationDTOTypeEnum.BUILDTORENT}>Build to Rent</option>
                                <option value={CreateOrganizationDTOTypeEnum.PRIVATE}>Private Landlord</option>
                              </Select>
                            </FieldWithLabel>
                          </Field>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} style={{ padding: 0 }}>
                          <Field>
                            <FieldWithLabel
                              label="Primary Vertical *"
                              touched={touched.primaryVertical}
                              error={errors.primaryVertical}
                            >
                              <Select
                                onChange={(vertical) => {
                                  setValues({
                                    ...values,
                                    primaryVertical: vertical,
                                    secondaryVerticals: values.secondaryVerticals.filter((v) => v !== vertical),
                                  });
                                }}
                                value={values.primaryVertical}
                              >
                                {verticalOptions.map((vertical) => (
                                  <option value={vertical.name}>{vertical.label}</option>
                                ))}
                              </Select>
                            </FieldWithLabel>
                          </Field>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} style={{ padding: '0 0 10px 0' }}>
                          <Field>
                            <FieldWithLabel label="Secondary Verticals">
                              {verticalOptions.map((vertical) => {
                                const { secondaryVerticals } = values;
                                const isPrimaryVertical = values.primaryVertical === vertical.name;
                                const verticalId = String(vertical.name) as CreateOrganizationDTOSecondaryVerticalsEnum;
                                const isSelected = secondaryVerticals.includes(verticalId);

                                return (
                                  <Checkbox
                                    key={vertical.name}
                                    label={`${vertical.label}${isPrimaryVertical ? ' (Primary)' : ''}`}
                                    name={vertical.name}
                                    isDisabled={isPrimaryVertical}
                                    isChecked={isSelected}
                                    onChange={(e) => {
                                      const newSecondaryVerticals = e.target.checked
                                        ? [...secondaryVerticals, verticalId]
                                        : secondaryVerticals.filter((v) => v !== verticalId);

                                      setFieldValue('secondaryVerticals', newSecondaryVerticals);
                                    }}
                                  />
                                );
                              })}
                            </FieldWithLabel>
                          </Field>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} style={{ padding: 0 }}>
                          <Field>
                            <FieldWithLabel
                              label="Reposit Sales Owner *"
                              touched={touched.salesOwnerId}
                              error={errors.salesOwnerId}
                            >
                              <Sort
                                label="salesOwnerId"
                                onSelect={(val) => setFieldValue('salesOwnerId', val)}
                                options={[{ value: 'none', label: 'None' }, ...internalTeamMemberSalesOptions]}
                                selected={values.salesOwnerId || ''}
                                hideLabel
                              />
                            </FieldWithLabel>
                          </Field>
                        </Col>
                      </Row>
                    </Container>
                  </RepositCard>
                </Col>

                <Col lg={12}>
                  <RepositCard title="Organisation Admin Details" subtitle="This is the main user on the account">
                    <Container fluid>
                      <Row>
                        <Col lg={12} style={{ padding: 0 }}>
                          <Field>
                            <FieldWithLabel label="Email address *" touched={touched.email} error={errors.email}>
                              <Input
                                name="email"
                                value={values.email}
                                error={errors.email}
                                touched={touched.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </FieldWithLabel>
                          </Field>
                        </Col>
                      </Row>
                    </Container>
                  </RepositCard>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Actions>
                    <ActionInfo>Creating an organisation will immediatly email the admin on this account</ActionInfo>
                    <Button buttonType="primary" disabled={!isValid}>
                      Create Organisation
                    </Button>
                  </Actions>
                </Col>
              </Row>
            </Container>
          </form>
        );
      }}
    </Formik>
  );
};

export default AddNewOrganisation;
