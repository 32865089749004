import React from "react";
import Modal from "../../../components/Modal";
import { Header3, P1 } from "../../../components/Typography";
import { acknowledgeCaseSentToInsurerRequested, setCurrentRepositActionModal } from "../../../redux/reposit/reposit.actions";
import { useDispatch } from "react-redux";
import Button from '../../../components/Button';
import styled from "styled-components";
const ActionBar = styled.div`
  display: flex;
  padding: 24px 0;
  justify-content: flex-end;
  flex-wrap: wrap;
  row-gap: 10px;

  button {
    margin-right: 10px;
  }
`;

interface SendPolicyModalProps {
  claimId: string;
  tenancyOrderId: string;
}

export const SendPolicyModal: React.FC<SendPolicyModalProps> = ({ claimId, tenancyOrderId }) => {
  const dispatch = useDispatch();
  return <Modal onDismiss={() => dispatch(setCurrentRepositActionModal({ name: '' }))}>
    <Header3>Acknowledgement of sending policy</Header3>
    <P1>
      Are you ready to acknowledge that you're sending the policy?
      This will notify the supplier.
    </P1>
    <ActionBar>
      <Button buttonType="secondary">Cancel</Button>
      <Button
        buttonType="primary"
        onClick={() => dispatch(acknowledgeCaseSentToInsurerRequested({ claimId, tenancyOrderId }))}
      >OK</Button>
    </ActionBar>
  </Modal>
};
