import { createReducer } from '@reduxjs/toolkit';
import { clearOrganizationVertical, fetchOrganizationVerticalSuccess } from './organization-vertical.actions';
import { OrganizationVerticalState } from './organization-vertical.types';

const initialState: OrganizationVerticalState = {
  organizationVerticals: [],
};

export default createReducer(initialState, (builder) => {
  builder.addCase(fetchOrganizationVerticalSuccess, (state, action) => {
    state.organizationVerticals = action.payload;
  });
  builder.addCase(clearOrganizationVertical, (state) => {
    state.organizationVerticals = [];
  });
});
